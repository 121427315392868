import React from 'react';
import AsyncComponent from '../../components/AsyncComponent';
import { Container, Col, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import LoadingPage from '../../components/LoadingPage';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import AccessForbidden from '../../components/AccessForbidden';
import User from '../../data/User';
import './styles.scss';
import InvoiceService from '../../services/InvoiceService';
import i18n from '../../i18n';


class Invoices extends AsyncComponent {
  constructor(props) {
    super(props);

    const date = new Date();
    this.state = {
      amountSms: 0,
      amountVoice: 0,
      amountWithoutTaxes: 0,
      cityId: '',
      invoiceId: null,
      loading: false,
      month: date.getMonth() + 1,
      monthSelected: date,
      numberSmsSent: 0,
      numberVoiceMinutes: 0,
      smsPrice: 0.025,
      totalAmount: 0,
      tps: 0,
      tvq: 0,
      userCities: [],
      voicePrice: 0.05,
      year: date.getFullYear(),
    };

    this._handleMonthChange = this._handleMonthChange.bind(this);
  }

  async componentDidMount() {
    await this._getUserCities();
    await this._fetchInvoices(this.state.userCities[0].cityId, this.state.month, this.state.year);
  }

  async _getUserCities() {
    let userCities = await User.getInstance().getUserCities();
    userCities = userCities.filter(city => city.smsEnabled || city.voiceEnabled);

    await this.setStateAsync({ userCities });
  }

  async _handleMonthChange(monthSelected) {
    await this.setStateAsync({ monthSelected });
    await this._fetchInvoices(this.state.cityId, monthSelected.getMonth() + 1, monthSelected.getFullYear());
  }

  async _fetchInvoices(cityId, month, year) {
    await this.setStateAsync({ loading: true });
    const invoice = await InvoiceService.fetchInvoice(cityId, month, year);
    await this.setStateAsync({ cityId, month, year, ...invoice, loading: false });
  }

  _renderCitiesSelect() {
    if (this.state.userCities.length > 1) {
      return (
        <Row className='citiesSelectContainer'>
          <Col sm='6'>
            <FormControl variant='standard' fullWidth={true}>
              <InputLabel>{i18n.t('selectACityToConsult')}</InputLabel>
              <Select
                variant='standard'
                value={this.state.cityId}
                onChange={async (_event) => await this._fetchInvoices(_event.target.value, this.state.month, this.state.year)}
              >
                {
                  this.state.userCities.map(function (city) {
                    return <MenuItem key={city.cityId} value={city.cityId}>{city.name}</MenuItem>;
                  })
                }
              </Select>
            </FormControl>
          </Col>
        </Row>
      );
    }
  }

  _renderInvoice() {
    return (
      <React.Fragment>
        <Row>
          <Col sm='2'><h5>{i18n.t('quantity')}</h5></Col>
          <Col sm='5'><h5>{'Description'}</h5></Col>
          <Col sm='3'><h5>{i18n.t('rate')}</h5></Col>
          <Col sm='2'><h5>{'Total'}</h5></Col>
        </Row>
        <Row>
          <Col sm='2'>{this.state.numberSmsSent}</Col>
          <Col sm='5'>{i18n.t('smsSent')}</Col>
          <Col sm='3'>{this.state.smsPrice.toFixed(3)} $</Col>
          <Col sm='2'>{this.state.amountSms.toFixed(2)} $</Col>
        </Row>
        <Row>
          <Col sm='2'>{this.state.numberVoiceMinutes}</Col>
          <Col sm='5'>{i18n.t('callMinutes')}</Col>
          <Col sm='3'>{this.state.voicePrice.toFixed(3)} $</Col>
          <Col sm='2'>{this.state.amountVoice.toFixed(2)} $</Col>
        </Row>
        <br />
        <Row>
          <Col sm='2'></Col>
          <Col sm='5'></Col>
          <Col sm='3'><h5>{i18n.t('subTotal')}</h5></Col>
          <Col sm='2'>{this.state.amountWithoutTaxes.toFixed(2)} $</Col>
        </Row>
        <Row>
          <Col sm='2'></Col>
          <Col sm='5'></Col>
          <Col sm='3'><h5>{i18n.t('gst')}</h5></Col>
          <Col sm='2'>{this.state.tps.toFixed(2)} $</Col>
        </Row>
        <Row>
          <Col sm='2'></Col>
          <Col sm='5'></Col>
          <Col sm='3'><h5>{i18n.t('qst')}</h5></Col>
          <Col sm='2'>{this.state.tvq.toFixed(2)} $</Col>
        </Row>
        <Row>
          <Col sm='2'></Col>
          <Col sm='5'></Col>
          <Col sm='3'><h5>{'Total'}</h5></Col>
          <Col sm='2'><h5>{this.state.totalAmount.toFixed(2)} $</h5></Col>
        </Row>
      </React.Fragment >
    );
  }

  _renderInvoiceSelect() {
    return (
      <React.Fragment>
        <Row>
          <Col sm='4' className='invoiceSelectFieldTitle'>
            <h5>{i18n.t('selectABillingMonth')}</h5>
          </Col>
          <Col sm='8' className='invoiceSelectField'>
            <DatePicker
              selected={this.state.monthSelected}
              onChange={this._handleMonthChange}
              selectsStart
              maxDate={new Date()}
              dateFormat='MM/yyyy'
              showMonthYearPicker
              popperPlacement={'top'}
              popperModifiers={{
                flip: {
                  behavior: ['top']
                },
                preventOverflow: {
                  enabled: false
                },
                hide: {
                  enabled: false
                }
              }}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  render() {
    if (User.getInstance().getUser().access_level === 'super_admin' || User.getInstance().getUser().access_level === 'admin') {
      return (
        <Container className='Invoices'>
          {this.state.loading && <LoadingPage />}
          {this._renderCitiesSelect()}
          <Row>
            <h2 className={'pageTitle'}>{i18n.t('billing')}</h2>
          </Row>
          {this._renderInvoiceSelect()}
          <br />
          <br />
          {this._renderInvoice()}
        </Container>
      );
    }
    else {
      return (
        <AccessForbidden />
      );
    }
  }
}

export default Invoices;

