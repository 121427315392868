import React from 'react';
import AsyncComponent from '../../components/AsyncComponent';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { Container, Col, Row } from 'reactstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AccessForbidden from '../../components/AccessForbidden';
import { ProblemCategories } from '../../utils/Enums';
import CitiesService from '../../services/CitiesService';
import ProblemsService from '../../services/ProblemsService';
import User from '../../data/User';
import './styles.scss';
import i18n from '../../i18n';

class ReportsMessages extends AsyncComponent {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      cityCategories: [],
      reminderLevels: { 'high': '14', 'moderate': '7', 'low': '3' },
      open: false,
      openError: false,
      userCities: [],
    };

    this._handleClose = this._handleClose.bind(this);
    this._handleInputChange = this._handleInputChange.bind(this);
    this._updateReminderLevels = this._updateReminderLevels.bind(this);
    this._updateReportCategories = this._updateReportCategories.bind(this);
  }

  async componentDidMount() {
    await this._fetchProblemCategories();
    await this._getUserCities();
    await this._fetchCity(this.state.cityId);
  }

  async _fetchProblemCategories() {
    const categories = await ProblemsService.fetchProblemCategories();
    categories.sort((a, b) =>
      ProblemCategories[a.name].name > ProblemCategories[b.name].name ? 1
        : (ProblemCategories[a.name].name < ProblemCategories[b.name].name ? -1 : 0));
    await this.setStateAsync({ categories });
  }

  async _fetchCity(cityId) {
    await this.setStateAsync({ cityId });
    const city = await CitiesService.fetchCity(cityId);
    const cityCategories = city.report_categories;
    const reminderLevels = city.report_reminder_level;

    await this.setStateAsync({ cityCategories, reminderLevels });
  }

  async _getUserCities() {
    const userCities = (await User.getInstance().getUserCities()).filter(city => city.cityId !== 'null');
    await this.setStateAsync({ userCities, cityId: userCities[0].cityId });
  }

  async _updateReportCategories() {
    const reportCategories = {
      report_categories: this.state.cityCategories
    };

    const success = await CitiesService.updateReportCategories(reportCategories, this.state.cityId);
    success ? this._notify(i18n.t('successfullyModified')) : this._notifyError(i18n.t('errorDuringModification'));
  }

  async _updateReminderLevels() {
    if (parseInt(this.state.reminderLevels['high']) < parseInt(this.state.reminderLevels['moderate']) || parseInt(this.state.reminderLevels['moderate']) < parseInt(this.state.reminderLevels['low'])) {
      await this._handleOpenError(i18n.t('errorDuringModification'), i18n.t('youMustEnterConsistentValuesForTheThresholds'));
    }
    else if (this.state.reminderLevels['high'] === '' || this.state.reminderLevels['moderate'] === '' || this.state.reminderLevels['low'] === '' ||
      this.state.reminderLevels['high'].match(/^[0-9]+$/) == null || this.state.reminderLevels['moderate'].match(/^[0-9]+$/) == null ||
      this.state.reminderLevels['low'].match(/^[0-9]+$/) == null) {
      await this._handleOpenError(i18n.t('errorDuringModification'), i18n.t('youMustEnterNumericValuesForTheThresholds'));
    }
    else {
      const reminderLevels = {
        report_reminder_level: this.state.reminderLevels
      };

      const success = await CitiesService.updateReminderLevels(reminderLevels, this.state.cityId);
      success ? this._notify(i18n.t('successfullyModified')) : this._notifyError(i18n.t('errorDuringModification'));
    }
  }

  async _handleOpenError(errorTitle, errorMessage) {
    await this.setStateAsync({ openError: true, errorTitle, errorMessage });
  }

  async _handleClose() {
    await this.setStateAsync({
      open: false,
      openError: false,
      openCityError: false,
    });
  }

  async _handleInputChange(event) {
    const { name, value } = event.target;
    await this.setStateAsync({
      [name]: value
    });
  }

  _notify(message) {
    toast.success(message);
  }

  _notifyError(errorMessage) {
    toast.error(errorMessage);
  }

  async _updateReportCategoryMessage(category, newMessage) {
    const cityCategories = this.state.cityCategories;

    cityCategories[category].message = newMessage;
    await this.setStateAsync({ cityCategories });
  }

  async _updateReportCategoryEnabled(category, enabled) {
    const cityCategories = this.state.cityCategories;
    cityCategories[category].enabled = enabled;

    await this.setStateAsync({ cityCategories });
  }

  async _updateProblemReminderTrigger(level, newTrigger) {
    const reminderLevels = this.state.reminderLevels;

    reminderLevels[level] = newTrigger;
    await this.setStateAsync({ reminderLevels });
  }

  _renderCitiesSelect() {
    if (this.state.userCities.length > 1) {
      return (
        <Row className='citiesSelectContainer'>
          <Col sm='6'>
            <FormControl variant='standard' fullWidth={true}>
              <InputLabel>{i18n.t('selectACityToConsult')}</InputLabel>
              <Select
                variant='standard'
                value={this.state.cityId}
                onChange={async (_event) => await this._fetchCity(_event.target.value)}
              >
                <MenuItem key={0} value={0}>{i18n.t('allMyCities')} </MenuItem>;
                {
                  this.state.userCities.map(function (city) {
                    return <MenuItem key={city.cityId} value={city.cityId}>{city.name}</MenuItem>;
                  })
                }
              </Select>
            </FormControl>
          </Col>
        </Row>
      );
    }
  }

  _renderReportCategoriesHeader() {
    return (
      <Row className='reportHeader'>
        <Col sm='2'>
          <h5>{i18n.t('category')}</h5>
        </Col>
        <Col sm='7'>
          <h5>{i18n.t('messageOfAcknowledgmentOfReceiptOrNonProcessing')}</h5>
        </Col>
        <Col sm='3' className='alignCenter'>
          <h5>{i18n.t('active')}</h5>
        </Col>
      </Row>
    );
  }

  _renderSaveButton() {
    return (
      <Button
        variant='contained'
        onClick={this._updateReportCategories}
        className='saveMessagesButton'
      >
        {i18n.t('save')}
      </Button>
    );
  }

  _renderCityProblemCategoriesMessages() {
    return (
      Object.keys(ProblemCategories).map(category =>
        <Row key={category}>
          <Col sm='2'>
            <h6>{ProblemCategories[category].name}</h6>
          </Col>
          <Col sm='8'>
            {this.state.cityCategories[category] &&
              <TextField
                variant='standard'
                placeholder={i18n.t('addMessage')}
                fullWidth={true}
                label={'Message'}
                multiline={true}
                onChange={async (event) => await this._updateReportCategoryMessage(category, event.target.value)}
                value={this.state.cityCategories[category].message}
              />
            }
            <br />
          </Col>
          <Col sm='2' className='categoryCheckbox'>
            {this.state.cityCategories[category] &&
              <Checkbox
                checked={this.state.cityCategories[category].enabled}
                onChange={async (_event, value) => await this._updateReportCategoryEnabled(category, value)}
                color='default'
              />
            }
          </Col>
        </Row>
      )
    );
  }

  _renderProblemsReminderLevelTextfield(level) {
    return (
      <TextField
        variant='standard'
        placeholder={i18n.t('numberOfDaysSinceTheLastModificationOfAReport')}
        fullWidth={true}
        label={i18n.t('addDaysSinceLastUpdate')}
        onChange={async (event) => await this._updateProblemReminderTrigger(level, event.target.value)}
        value={this.state.reminderLevels[level]}
      />
    );
  }

  _renderProblemReminderLevels() {
    return (
      <React.Fragment>
        <Row>
          <h2 className={'pageTitle'}>{i18n.t('reportRecallLevels')}</h2>
        </Row>
        <Row>
          <Col sm='4'>
            <h6 className='reminderHigh reminder'>{i18n.t('highRecallLevel')}</h6>
          </Col>
          <Col sm='6'>
            {this._renderProblemsReminderLevelTextfield('high')}
          </Col>
        </Row>
        <Row>
          <Col sm='4'>
            <h6 className='reminderModerate reminder'>{i18n.t('moderateRecallLevel')}</h6>
          </Col>
          <Col sm='6'>
            {this._renderProblemsReminderLevelTextfield('moderate')}
          </Col>
        </Row>
        <Row>
          <Col sm='4'>
            <h6 className='reminderLow reminder'>{i18n.t('lowRecallLevel')}</h6>
          </Col>
          <Col sm='6'>
            {this._renderProblemsReminderLevelTextfield('low')}
          </Col>
        </Row>
        <Button
          variant='contained'
          onClick={this._updateReminderLevels}
          className='saveMessagesButton'
        >
          {i18n.t('save')}
        </Button>
      </React.Fragment>
    );
  }

  render() {
    if (User.getInstance().getUser().access_level === 'super_admin' || User.getInstance().getUser().access_level === 'admin') {
      return (
        <Container className='ReportsMessages'>
          {this._renderCitiesSelect()}
          <Row>
            <h2 className={'pageTitle'}>{i18n.t('managementOfReportingCategories')}</h2>
          </Row>
          {this._renderReportCategoriesHeader()}
          {this._renderCityProblemCategoriesMessages()}
          {this._renderSaveButton()}
          {this._renderProblemReminderLevels()}
          <Dialog
            open={this.state.openError}
            onClose={this._handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>{this.state.errorTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                {this.state.errorMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this._handleClose} color='primary'>
                {'Ok'}
              </Button>
            </DialogActions>
          </Dialog>
          <ToastContainer />
        </Container>
      );
    }
    else {
      return (
        <AccessForbidden />
      );
    }
  }
}

export default ReportsMessages;
