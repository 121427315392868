import React from 'react';
import AsyncComponent from '../../components/AsyncComponent';
import Button from '@mui/material/Button';
import { Container, Col, Row } from 'reactstrap';
import deburr from 'lodash/deburr';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import LoadingPage from '../../components/LoadingPage';
import MenuItem from '@mui/material/MenuItem';
import { orderBy } from '../../utils/array_helper';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { ToastContainer, toast } from 'react-toastify';

import AccessForbidden from '../../components/AccessForbidden';
import SubscriptionForm from '../../components/SubscriptionForm';
import SubscribersService from '../../services/SubscribersService';
import User from '../../data/User';
import './styles.scss';
import Table from '../../components/Table';
import i18n from '../../i18n';

const invertDirection = {
  asc: 'desc',
  desc: 'asc'
};

class Subscribers extends AsyncComponent {
  constructor(props) {
    super(props);
    this.state = {
      cityId: '',
      cities: [],
      columnToSort: 'name',
      loading: false,
      open: false,
      smsSubscribers: [],
      smsUserQuery: '',
      smsPhoneNumberQuery: '',
      sortDirection: 'asc',
      subscriberToDelete: {},
      userCities: [],
      voiceSubscribers: [],
      voicePhoneNumberQuery: '',
      voiceUserQuery: '',
    };

    this._deleteSubscriber = this._deleteSubscriber.bind(this);
    this._fetchSubscribers = this._fetchSubscribers.bind(this);
    this._handleClose = this._handleClose.bind(this);
    this._handleRemove = this._handleRemove.bind(this);
    this._handleSort = this._handleSort.bind(this);
    this._onSmsPhoneNumberQueryChange = this._onSmsPhoneNumberQueryChange.bind(this);
    this._onSmsUserQueryChange = this._onSmsUserQueryChange.bind(this);
    this._onVoicePhoneNumberQueryChange = this._onVoicePhoneNumberQueryChange.bind(this);
    this._onVoiceUserQueryChange = this._onVoiceUserQueryChange.bind(this);
  }

  async componentDidMount() {
    await this._getUserCities();
    await this._fetchSubscribers(this.state.userCities[0].cityId);
  }

  async _getUserCities() {
    let userCities = await User.getInstance().getUserCities();
    userCities = userCities.filter(city => city.smsEnabled || city.voiceEnabled);

    await this.setStateAsync({ userCities });
  }

  async _fetchCityUsers(cityId) {
    await this.setStateAsync({ cityId });
    await this._fetchSubscribers(cityId);
  }

  async _fetchSubscribers(cityId) {
    await this.setStateAsync({ loading: true });
    const city = cityId !== undefined ? cityId : this.state.cityId;
    const smsSubscribers = await SubscribersService.fetchSubscribers(city, 'sms');
    const voiceSubscribers = await SubscribersService.fetchSubscribers(city, 'voice');

    await this.setStateAsync({ smsSubscribers, voiceSubscribers, cityId, loading: false });
  }

  async _handleClose() {
    await this.setStateAsync({ open: false, subscriberToDelete: {} });
  }

  async _handleSort(columnName) {
    await this.setStateAsync(state => ({
      columnToSort: columnName,
      sortDirection:
        state.columnToSort === columnName
          ? invertDirection[state.sortDirection]
          : 'asc'
    }));
  }

  async _handleRemove(subscriber) {
    await this.setStateAsync({ open: true, subscriberToDelete: subscriber });
  }

  _notify(message) {
    toast.success(message);
  }

  _notifyError(errorMessage) {
    toast.error(errorMessage);
  }

  async _deleteSubscriber() {
    const subscriberToDelete = {
      phone_number: this.state.subscriberToDelete.phoneNumber,
      subscriber_type: this.state.subscriberToDelete.subscriberType,
      latlng: this.state.subscriberToDelete.latlng,
      city_id: this.state.subscriberToDelete.cityId
    };

    const success = await SubscribersService.unsubscribe(subscriberToDelete);
    if (!success) {
      this._notifyError(i18n.t('errorDuringDeletion'));
    }
    else {
      this._notify(i18n.t('successfullyRemoved'));

      if (this.state.subscriberToDelete.subscriberType === 'sms') {
        this.state.smsSubscribers = this.state.smsSubscribers.filter(subscriber => (subscriber.id !== this.state.subscriberToDelete.id));
      }
      else {
        this.state.voiceSubscribers = this.state.voiceSubscribers.filter(subscriber => (subscriber.id !== this.state.subscriberToDelete.id));
      }
    }

    await this.setStateAsync({ open: false, subscriberToDelete: {} });
  }

  async _onSmsUserQueryChange(e) {
    await this.setStateAsync({ smsUserQuery: e.target.value });
  }

  async _onSmsPhoneNumberQueryChange(e) {
    await this.setStateAsync({ smsPhoneNumberQuery: e.target.value });
  }

  async _onVoiceUserQueryChange(e) {
    await this.setStateAsync({ voiceUserQuery: e.target.value });
  }

  async _onVoicePhoneNumberQueryChange(e) {
    await this.setStateAsync({ voicePhoneNumberQuery: e.target.value });
  }

  _renderCitiesSelect() {
    if (this.state.userCities.length > 1) {
      return (
        <Row className='citiesSelectContainer'>
          <Col sm='6'>
            <FormControl variant='standard' fullWidth={true}>
              <InputLabel>{i18n.t('selectACityToConsult')}</InputLabel>
              <Select
                variant='standard'
                value={this.state.cityId}
                onChange={async (_event) => await this._fetchCityUsers(_event.target.value)}
              >
                {
                  this.state.userCities.map(function (city) {
                    return <MenuItem key={city.cityId} value={city.cityId}>{city.name}</MenuItem>;
                  })
                }
              </Select>
            </FormControl>
          </Col>
        </Row>
      );
    }
  }

  _renderSubscribersDatatables() {
    const lowerCaseSmsQuery = deburr(this.state.smsUserQuery.toLowerCase());
    const lowerCaseSmsPhoneNumberQuery = deburr(this.state.smsPhoneNumberQuery.toLowerCase());
    const lowerCaseVoiceQuery = deburr(this.state.voiceUserQuery.toLowerCase());
    const lowerCaseVoicePhoneNumberQuery = deburr(this.state.voicePhoneNumberQuery.toLowerCase());
    const city = this.state.userCities.find(city => city.cityId === this.state.cityId);

    return (
      <React.Fragment>
        {
          city != undefined && city.smsEnabled &&
          <React.Fragment>
            <Row>
              <Col sm='12'>
                <h4 className='title'>{i18n.t('numberOfSubscriptionsToSmsAlerts')}: {this.state.smsSubscribers.length}</h4>
              </Col>
            </Row>
            <Row>
              <Col sm='6'>
                <TextField
                  variant='standard'
                  placeholder={i18n.t('enterTheUserName')}
                  fullWidth={true}
                  label={i18n.t('searchForAUser')}
                  maxLength='255'
                  onChange={this._onSmsUserQueryChange}
                  value={this.state.smsUserQuery}
                />
              </Col>
              <Col sm='6'>
                <TextField
                  variant='standard'
                  placeholder={i18n.t('enterTheUserPhoneNumber')}
                  fullWidth={true}
                  label={i18n.t('searchForAPhoneNumber')}
                  maxLength='255'
                  onChange={this._onSmsPhoneNumberQueryChange}
                  value={this.state.smsPhoneNumberQuery}
                />
              </Col>
            </Row>
            <Table
              sortDirection={this.state.sortDirection}
              columnToSort={this.state.columnToSort}
              handleSort={this._handleSort}
              deleteAction={true}
              blockAction={false}
              handleRemove={this._handleRemove}
              data={orderBy(
                (lowerCaseSmsQuery || lowerCaseSmsPhoneNumberQuery)
                  ? this.state.smsSubscribers.filter(x => (
                    deburr(x['name'])
                      .toLowerCase()
                      .includes(lowerCaseSmsQuery)
                    && deburr(x['phoneNumber'])
                      .toLowerCase()
                      .includes(lowerCaseSmsPhoneNumberQuery)
                  )
                  )
                  : this.state.smsSubscribers,
                this.state.columnToSort,
                this.state.sortDirection)}
              header={[
                {
                  name: i18n.t('name'),
                  prop: 'name'
                },
                {
                  name: i18n.t('phoneNumber'),
                  prop: 'phoneNumber'
                },
                {
                  name: i18n.t('creationDate'),
                  prop: 'createdAt'
                },
                {
                  name: i18n.t('subscriberAddress'),
                  prop: 'latlng',
                  generateContent: (latlng) => (
                    latlng
                      ? <a href={'https://maps.google.com/maps?q=' + latlng.lat + ',' + latlng.lng} className='label'>
                        {latlng.lat + ',' + latlng.lng}
                      </a>
                      : '')
                }
              ]}
            />
          </React.Fragment>
        }
        {
          city != undefined && city.voiceEnabled &&
          <React.Fragment>
            <Row>
              <Col sm='12'>
                <h4 className='title'>{i18n.t('numberOfSubscriptionsToAutomatedCalls')}: {this.state.voiceSubscribers.length}</h4>
              </Col>
            </Row>
            <Row>
              <Col sm='6'>
                <TextField
                  variant='standard'
                  placeholder={i18n.t('enterTheUserName')}
                  fullWidth={true}
                  label={i18n.t('searchForAUser')}
                  maxLength='255'
                  onChange={this._onVoiceUserQueryChange}
                  value={this.state.voiceUserQuery}
                />
              </Col>
              <Col sm='6'>
                <TextField
                  variant='standard'
                  placeholder={i18n.t('enterTheUserPhoneNumber')}
                  fullWidth={true}
                  label={i18n.t('searchForAPhoneNumber')}
                  maxLength='255'
                  onChange={this._onVoicePhoneNumberQueryChange}
                  value={this.state.voicePhoneNumberQuery}
                />
              </Col>
            </Row>
            <Table
              sortDirection={this.state.sortDirection}
              columnToSort={this.state.columnToSort}
              handleSort={this._handleSort}
              blockAction={false}
              deleteAction={true}
              handleRemove={this._handleRemove}
              data={orderBy(
                (lowerCaseVoiceQuery || lowerCaseVoicePhoneNumberQuery)
                  ? this.state.voiceSubscribers.filter(x => (
                    deburr(x['name'])
                      .toLowerCase()
                      .includes(lowerCaseVoiceQuery)
                    && deburr(x['phoneNumber'])
                      .toLowerCase()
                      .includes(lowerCaseVoicePhoneNumberQuery)
                  )
                  )
                  : this.state.voiceSubscribers,
                this.state.columnToSort,
                this.state.sortDirection)}
              header={[
                {
                  name: i18n.t('name'),
                  prop: 'name'
                },
                {
                  name: i18n.t('phoneNumber'),
                  prop: 'phoneNumber'
                },
                {
                  name: i18n.t('creationDate'),
                  prop: 'createdAt'
                },
                {
                  name: i18n.t('subscriberAddress'),
                  prop: 'latlng',
                  generateContent: (latlng) => (
                    latlng
                      ? <a href={'https://maps.google.com/maps?q=' + latlng.lat + ',' + latlng.lng} className='label'>
                        {latlng.lat + ',' + latlng.lng}
                      </a>
                      : '')
                }
              ]}
            />
          </React.Fragment>
        }
        <Dialog
          open={this.state.open}
          onClose={this._handleClose}
          aria-labelledby='user-dialog-title'
          aria-describedby='user-dialog-description'
        >
          <DialogTitle id='user-dialog-title'>{i18n.t('confirmationOfDeletion')}</DialogTitle>
          <DialogContent>
            <DialogContentText id='user-dialog-description'>
              {i18n.t('areYouSureYouWantToDeleteThis')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this._handleClose} color='primary'>
              {i18n.t('cancel')}
            </Button>
            <Button autoFocus={true} onClick={this._deleteSubscriber} color='primary'>
              {i18n.t('confirm')}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

  render() {
    if (User.getInstance().getUser().access_level === 'admin' || User.getInstance().getUser().access_level === 'publisher_citizens' || User.getInstance().getUser().access_level === 'super_admin') {
      return (
        <Container className='Subscribers'>
          {this.state.loading && <LoadingPage />}
          <Row>
            <h2 className={'pageTitle'}>{i18n.t('subscriptions')}</h2>
          </Row>
          <SubscriptionForm fetchSubscribers={this._fetchSubscribers} cities={this.state.userCities} />
          <br />
          {this._renderCitiesSelect()}
          {this._renderSubscribersDatatables()}
          <ToastContainer />
        </Container>
      );
    }
    else {
      return (
        <AccessForbidden />
      );
    }
  }
}

export default Subscribers;
