import React from 'react';
import AsyncComponent from '../components/AsyncComponent';
import './styles.scss';
import { Link } from 'react-router-dom';
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  NavbarToggler,
  UncontrolledDropdown,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';

import Cities from '../data/Cities';
import User from '../data/User';
import i18n from '../i18n';

import Avatar from '../images/avatar.png';
import HeaderLogo from '../images/logo_header_carecityapp.png';

class Header extends AsyncComponent {
  constructor(props) {
    super(props);

    this.user = User.getInstance().getUser();

    this.state = {
      canUseNightParking: false,
      cityName: 'Carecity',
      collapsed: false,
      firstLogin: true,
      language: i18n.getLanguageEnabled(),
      smsEnabled: false,
      voiceEnabled: false,
    };

    this._toggleNavbar = this._toggleNavbar.bind(this);
    this._logout = this._logout.bind(this);
    this._changeLanguage = this._changeLanguage.bind(this);
  }

  async componentDidMount() {
    await this.componentDidUpdate();
  }

  async componentDidUpdate() {
    this.user = User.getInstance().getUser();
    if (this.user && this.state.firstLogin) {
      await this.setStateAsync({ firstLogin: false });
      await this._setCanUseNightParking();
      await this._fetchCityName();
    }
    else if (!this.user && !this.state.firstLogin) {
      await this.setStateAsync({ firstLogin: true });
    }
  }

  async _changeLanguage() {
    const newLanguage = i18n.getLanguageEnabled() === 'fr' ? 'en' : 'fr';
    i18n.changeLanguage(newLanguage);
    window.location.reload();
  }

  async _setCanUseNightParking() {
    const userCities = await User.getInstance().getUserCities();
    const canUseNightParking = userCities.some(city => city.parkingEnabled);
    const smsEnabled = userCities.some(city => city.smsEnabled);
    const voiceEnabled = userCities.some(city => city.voiceEnabled);

    await this.setStateAsync({ canUseNightParking, smsEnabled, voiceEnabled });
  }

  async _fetchCityName() {
    const cityName = this.user && this.user.city_ids && this.user.city_ids[0] && this.user.city_ids.length === 1 ? await Cities.getInstance().getCitiesName(this.user.city_ids) : 'Carecity';
    await this.setStateAsync({ cityName });
  }

  async _logout() {
    User.getInstance().logout();
    await this.setStateAsync({ firstLogin: true });
    this.props.navigate('/login');
  }

  async _toggleNavbar() {
    await this.setStateAsync({
      collapsed: !this.state.collapsed
    });
  }

  _renderPublicWorkNavItems() {
    if (this.state.canUseNightParking) {
      return (
        <UncontrolledDropdown nav inNavbar className='buttonDropdown'>
          <DropdownToggle caret nav>
            {i18n.t('publicWorks')}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem tag={Link} to='/'>{i18n.t('reports')}</DropdownItem>
            <DropdownItem divider />
            <DropdownItem tag={Link} to='/Parking'>{i18n.t('parking')}</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
    else {
      return (
        <NavItem className='singleNavItem'>
          <NavLink tag={Link} to='/'>{i18n.t('reports')}</NavLink>
        </NavItem>
      );
    }
  }

  _renderCommunicationNavItems() {
    return (
      <UncontrolledDropdown nav inNavbar className='buttonDropdown'>
        <DropdownToggle caret nav>
          Communications
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem tag={Link} to='/Alerts'>{i18n.t('alertHeader')}</DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag={Link} to='/Surveys'>{i18n.t('surveys')}</DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag={Link} to='/Portail'>{i18n.t('portal')}</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  _renderAdminParametersNavItems() {
    const isSuperAdmin = this.user.access_level === 'super_admin';
    return (
      <UncontrolledDropdown nav inNavbar className='buttonDropdown'>
        <DropdownToggle caret nav>
          {i18n.t('settingsHeader')}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem tag={Link} to='/Groups'>{i18n.t('groups')}</DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag={Link} to='/Redirections'>Redirections</DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag={Link} to='/Users'>{i18n.t('municipalUsers')}</DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag={Link} to='/ReportsMessages'>{i18n.t('reports')}</DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag={Link} to='/Stats'>{i18n.t('statistics')}</DropdownItem>
          {isSuperAdmin && <DropdownItem divider />}
          {isSuperAdmin && <DropdownItem header>{i18n.t('superAdministrator')}</DropdownItem>}
          {isSuperAdmin && <DropdownItem tag={Link} to='/AuthorisedReports'>{i18n.t('authorizedReports')}</DropdownItem>}
          {isSuperAdmin && <DropdownItem divider />}
          {isSuperAdmin && <DropdownItem tag={Link} to='/Citizens'>{i18n.t('mobileUsers')}</DropdownItem>}
          {isSuperAdmin && <DropdownItem divider />}
          {isSuperAdmin && <DropdownItem tag={Link} to='/Cities'>{i18n.t('cities')}</DropdownItem>}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  _renderLanguageSelector() {
    return (
      <NavbarBrand className='languageSelector' onClick={this._changeLanguage}>
        {this.state.language === 'fr' ? 'EN' : 'FR'}
      </NavbarBrand>
    );
  }

  _renderProfilNavItems() {
    return (
      <UncontrolledDropdown nav inNavbar direction='left' className='profil'>
        <DropdownToggle nav>
          <img
            src={Avatar}
            className='avatar'
            alt='Profil'
          />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>{i18n.t('profilHeader')}</DropdownItem>
          <DropdownItem tag={Link} to='/Password'>{i18n.t('password')}</DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={this._logout}>{i18n.t('logout')}</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  _renderSmsAndVoiceNavItems() {
    return (
      <UncontrolledDropdown nav inNavbar className='buttonDropdown'>
        <DropdownToggle caret nav>
          {i18n.t('callsAndSMS')}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem tag={Link} to='/Subscribers'>{i18n.t('subscriptions')}</DropdownItem>
          {(this.user.access_level === 'super_admin' || this.user.access_level === 'admin') &&
            <React.Fragment>
              <DropdownItem divider />
              <DropdownItem tag={Link} to='/Invoices'>{i18n.t('billing')}</DropdownItem>
            </React.Fragment>
          }
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  render() {
    if (!this.user) {
      return (
        <div></div>
      );
    }
    else {
      return (
        <Navbar dark expand='md' stylename='Header'>
          <NavbarBrand tag={Link} to={this.user.access_level === 'publisher_communications' ? '/Alerts' : '/'}>
            <img
              className='logo_nav'
              alt='Carecity'
              src={HeaderLogo} />
          </NavbarBrand>
          <NavbarBrand className='cityName'>
            <h3>
              {this.state.cityName}
            </h3>
          </NavbarBrand>
          <NavbarToggler onClick={this._toggleNavbar} />
          <Collapse isOpen={this.state.collapsed} navbar>
            <Nav className='ms-auto' navbar>
              {(this.user.access_level === 'publisher_public_works' || this.user.access_level === 'admin' || this.user.access_level === 'super_admin') && this._renderPublicWorkNavItems()}
              {(this.user.access_level === 'publisher_communications' || this.user.access_level === 'admin' || this.user.access_level === 'super_admin') && this._renderCommunicationNavItems()}
              {(this.state.smsEnabled || this.state.voiceEnabled) && (this.user.access_level === 'admin' || this.user.access_level === 'super_admin' || this.user.access_level === 'publisher_citizens') && this._renderSmsAndVoiceNavItems()}
              {(this.user.access_level === 'admin' || this.user.access_level === 'super_admin') && this._renderAdminParametersNavItems()}
              {this._renderProfilNavItems()}
              {this._renderLanguageSelector()}
            </Nav>
          </Collapse>
        </Navbar>
      );
    }
  }
}

function HeaderLayout(props) {
  const navigate = useNavigate();

  return (
    <Header navigate={navigate} {...props} />
  );
}

export default HeaderLayout;