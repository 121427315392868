import React from 'react';
import AsyncComponent from '../../components/AsyncComponent';
import Button from '@mui/material/Button';
import { Container, Col, Form, Row } from 'reactstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Multiselect from 'react-select';
import orderBy from 'lodash/orderBy';
import Select from '@mui/material/Select';
import Table from '../../components/Table';
import TextField from '@mui/material/TextField';
import { ToastContainer, toast } from 'react-toastify';
import validator from 'validator';

import AccessForbidden from '../../components/AccessForbidden';
import Cities from '../../data/Cities';
import User from '../../data/User';
import UsersService from '../../services/UsersService';
import './styles.scss';
import i18n from '../../i18n';

const invertDirection = {
  asc: 'desc',
  desc: 'asc'
};

class Users extends AsyncComponent {
  constructor(props) {
    super(props);
    this.state = {
      accessLevel: '',
      availableAccessLevels: {},
      cityToFetch: 0,
      columnToSort: 'city',
      email: '',
      errorMessage: '',
      errorTitle: '',
      open: false,
      openError: false,
      openModification: false,
      sortDirection: 'asc',
      emailQuery: '',
      userCities: [],
      userAuthorizedToSeeCitizensAccessLevel: false,
      userCitiesSelected: [],
      userEdited: null,
      users: [],
      userToDelete: {},
    };

    this._addUser = this._addUser.bind(this);
    this._deleteUser = this._deleteUser.bind(this);
    this._editNotSupportedReason = this._editNotSupportedReason.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._handleChangeEdit = this._handleChangeEdit.bind(this);
    this._handleClose = this._handleClose.bind(this);
    this._handleInputChange = this._handleInputChange.bind(this);
    this._handleSelectMultiCitiesChange = this._handleSelectMultiCitiesChange.bind(this);
    this._handleRemove = this._handleRemove.bind(this);
    this._handleSort = this._handleSort.bind(this);
    this._isEditSupported = this._isEditSupported.bind(this);
    this._isRemoveSupported = this._isRemoveSupported.bind(this);
    this._modifyUser = this._modifyUser.bind(this);
    this._onUserEmailQueryChange = this._onUserEmailQueryChange.bind(this);
    this._toggleEditing = this._toggleEditing.bind(this);
  }

  async componentDidMount() {
    await this._getUserCities();
    await this._fetchUsers();
  }

  async _addUser() {
    if (this.state.email !== '' && validator.isEmail(this.state.email.trim())) {
      const user = {
        email: this.state.email.trim(),
        access_level: this.state.accessLevel,
        city_ids: this.state.userCitiesSelected.map(city => city.value),
      };

      const success = await UsersService.addUser(user);
      if (success) {
        this._notify(i18n.t('successfullyCreated'));
        await this._fetchUsers();
        await this.setStateAsync({
          accessLevel: 'admin',
          email: '',
          userCitiesSelected: []
        });

        if (this.state.userCities.length === 1) {
          await this.setStateAsync({ userCitiesSelected: [{ value: this.state.userCities[0].cityId, label: this.state.userCities[0].name }] });
        }
      }
      else {
        this._notifyError(i18n.t('errorDuringCreation'));
      }
    }
    else {
      await this._handleOpenError(i18n.t('errorDuringCreation'), i18n.t('youMustEnterAValidEmailAddress'));
    }
  }

  async _modifyUser() {
    const success = await UsersService.editUser(this.state.userEdited);
    if (success) {
      this._notify(i18n.t('successfullyModified'));
      await this._fetchUsers();
      await this.setStateAsync({
        openModification: false,
        userEdited: null,
      });
    }
    else {
      this._notifyError(i18n.t('errorDuringModification'));
    }
  }

  async _deleteUser() {
    const success = await UsersService.deleteUser(this.state.userToDelete.email);
    if (success) {
      this._notify(i18n.t('successfullyRemoved'));
      this.state.users = this.state.users.filter(user => user.email !== this.state.userToDelete.email);
    }
    else {
      this._notifyError(i18n.t('errorDuringDeletion'));
    }

    await this.setStateAsync({ open: false });
  }

  async _getUserCities() {
    const userCitiesWithSmsVoice = (await User.getInstance().getUserCities()).filter(city => city.smsEnabled || city.voiceEnabled);

    const availableAccessLevels = AccessLevels;
    if (userCitiesWithSmsVoice.length === 0) {
      delete availableAccessLevels.publisher_citizens;
    }
    if (User.getInstance().getUser().access_level !== 'super_admin') {
      delete availableAccessLevels.super_admin;
    }

    const userCities = await User.getInstance().getUserCities();
    await this.setStateAsync({ userCities, availableAccessLevels, accessLevel: 'admin' });

    if (userCities.length === 1) {
      await this.setStateAsync({ userCitiesSelected: [{ value: userCities[0].cityId, label: userCities[0].name }], cityToFetch: userCities[0].cityId });
    }
  }

  async _fetchUsers() {
    const userCityIds = User.getInstance().getUser().city_ids;
    const citiesToFetchUsersFrom = this.state.cityToFetch === 0 ? userCityIds : [this.state.cityToFetch];
    const users = await UsersService.fetchUsers(citiesToFetchUsersFrom);
    users.sort((a, b) => {
      let x = a.cityId, y = b.cityId;
      if (x === y) {
        x = a.accessLevel;
        y = b.accessLevel;
        if (x === y) {
          x = a.email.toLowerCase();
          y = b.email.toLowerCase();
        }
      }

      return x > y;
    });

    await Promise.all(users.map(async user => {
      user.cityName = await Cities.getInstance().getCitiesName(user.city_ids);
      user.accessLevelName = AccessLevels[user.accessLevel];
    }));

    await this.setStateAsync({ users });
  }

  async _filterUsers(cityToFetch) {
    await this.setStateAsync({ cityToFetch });
    await this._fetchUsers();
  }

  async _toggleEditing(user) {
    const userEdited = JSON.parse(JSON.stringify(user));

    await this.setStateAsync({
      userEdited,
      openModification: true,
    });
  }

  async _cancelEditing(_event, reason) {
    if (reason && reason === 'backdropClick')
      return false;

    await this.setStateAsync({
      openModification: false,
      userEdited: null,
    });
  }

  _editNotSupportedReason() {
    return i18n.t('cannotEditThisUser');
  }

  async _handleChange(_event) {
    await this.setStateAsync({ accessLevel: _event.target.value });
  }

  async _handleChangeEdit(event) {
    this.state.userEdited.accessLevel = event.target.value;
    await this.forceUpdateAsync();
  }

  async _handleClose() {
    await this.setStateAsync({ open: false, openError: false });
  }

  async _handleInputChange(event) {
    const { name, value } = event.target;
    await this.setStateAsync({
      [name]: value
    });
  }

  async _handleRemove(user) {
    if (user.email === User.getInstance().getUser().email) {
      await this._handleOpenError(i18n.t('errorDuringDeletion'), i18n.t('youCannotDeleteYourUser'));
    }
    else {
      await this.setStateAsync({ open: true, userToDelete: user });
    }
  }

  async _handleOpenError(errorTitle, errorMessage) {
    await this.setStateAsync({ openError: true, errorTitle, errorMessage });
  }

  async _handleSort(columnName) {
    await this.setStateAsync(state => ({
      columnToSort: columnName,
      sortDirection:
        state.columnToSort === columnName
          ? invertDirection[state.sortDirection]
          : 'asc'
    }));
  }

  async _handleSelectMultiCitiesChange(cities) {
    await this.setStateAsync({ userCitiesSelected: cities ? cities : [] });
  }

  _isEditSupported(user) {
    const currentUser = User.getInstance().getUser();
    if ((currentUser.email === user.email) || (currentUser.accessLevel === 'admin' && user.accessLevel === 'super_admin')) {
      return false;
    }
    else {
      return true;
    }
  }

  _isRemoveSupported(user) {
    const currentUser = User.getInstance().getUser();
    return currentUser.email !== user.email;
  }

  _notify(message) {
    toast.success(message);
  }

  _notifyError(errorMessage) {
    toast.error(errorMessage);
  }

  async _onUserEmailQueryChange(e) {
    await this.setStateAsync({ emailQuery: e.target.value });
  }

  _renderUserModicationForm() {
    if (this.state.userEdited !== null) {
      return (
        <Dialog
          open={this.state.openModification}
          maxWidth='md'
          fullWidth={true}
          onClose={this._cancelEditing}
        >
          <h3 className='editUserTitle'>{i18n.t('userEdit')}</h3>
          <DialogContent>
            <form>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <p>{this.state.userEdited.email}</p>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <p>{this.state.userEdited.cityName}</p>
                </Col>
              </Row>
              <Row className='editUserFieldContainer'>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <FormControl variant='standard' fullWidth={true}>
                    <InputLabel>{i18n.t('accessLevel')}</InputLabel>
                    <Select
                      variant='standard'
                      value={this.state.userEdited.accessLevel}
                      onChange={this._handleChangeEdit}
                    >
                      {
                        Object.entries(this.state.availableAccessLevels).map(([key, value]) => {
                          return <MenuItem key={key} value={key}>{value}</MenuItem>;
                        })
                      }
                    </Select>
                  </FormControl>
                </Col>
              </Row>
            </form>
          </DialogContent>
          <DialogActions className='editUserButtonsContainer'>
            <Button
              variant='outlined'
              onClick={async () => await this._cancelEditing()}
              className='editUserCancelButton'
            >
              {i18n.t('cancel')}
            </Button>
            <Button
              variant='outlined'
              onClick={async () => await this._modifyUser()}
              className='editUserConfirmButton'
            >
              {i18n.t('edit')}
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  }

  _renderUsersTable() {
    const lowerCaseQuery = this.state.emailQuery.toLowerCase();
    return (
      <div className='activeUserContainer'>
        <Row>
          <Col sm='6'>
            <TextField
              variant='standard'
              placeholder={i18n.t('enterTheUserEmail')}
              fullWidth={true}
              label={i18n.t('searchForAUser')}
              maxLength='255'
              onChange={this._onUserEmailQueryChange}
              value={this.state.emailQuery}
            />
          </Col>
          {
            this.state.userCities.length > 1 &&
            <Col sm='6'>
              <FormControl variant='standard' fullWidth={true}>
                <InputLabel>{i18n.t('carecityCities')}</InputLabel>
                <Select
                  variant='standard'
                  value={this.state.cityToFetch}
                  onChange={async (_event) => await this._filterUsers(_event.target.value)}
                >
                  <MenuItem key={0} value={0}>{i18n.t('allMyCities')} </MenuItem>;
                  {
                    this.state.userCities.map(function (city) {
                      return <MenuItem key={city.cityId} value={city.cityId}>{city.name}</MenuItem>;
                    })
                  }
                </Select>
              </FormControl>
            </Col>
          }
        </Row>
        <Table
          sortDirection={this.state.sortDirection}
          columnToSort={this.state.columnToSort}
          handleSort={this._handleSort}
          handleRemove={this._handleRemove}
          handleEdit={this._toggleEditing}
          isEditSupported={this._isEditSupported}
          isRemoveSupported={this._isRemoveSupported}
          editNotSupportedReason={this._editNotSupportedReason}
          deleteAction={true}
          blockAction={false}
          data={orderBy(
            this.state.emailQuery
              ? this.state.users.filter(x =>
                x['email']
                  .toLowerCase()
                  .includes(lowerCaseQuery)
              )
              : this.state.users,
            this.state.columnToSort,
            this.state.sortDirection)}
          header={[
            {
              name: i18n.t('email'),
              prop: 'email'
            },
            {
              name: i18n.t('accessLevel'),
              prop: 'accessLevelName'
            },
            (this.state.userCities.length > 1) &&
            {
              name: i18n.t('city'),
              prop: 'cityName'
            }
          ]}
        />
        <Dialog
          open={this.state.open}
          onClose={this._handleClose}
          aria-labelledby='user-dialog-title'
          aria-describedby='user-dialog-description'
        >
          <DialogTitle id='user-dialog-title'>{i18n.t('confirmationOfDeletion')}</DialogTitle>
          <DialogContent>
            <DialogContentText id='user-dialog-description'>
              {i18n.t('areYouSureYouWantToDeleteThis')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this._handleClose} color='primary'>
              {i18n.t('cancel')}
            </Button>
            <Button onClick={this._deleteUser} color='primary'>
              {i18n.t('confirm')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  _renderCitiesSelect() {
    if (this.state.userCities.length > 1) {
      return (
        <Row>
          <Col sm='12'>
            <Multiselect
              label={i18n.t('userCities')}
              isMulti={true}
              onChange={this._handleSelectMultiCitiesChange}
              options={this.state.userCities.filter(city => city.cityId !== 'null').map((city) => {
                return { label: city.name, value: city.cityId };
              })}
              placeholder={i18n.t('userCities')}
              value={this.state.userCitiesSelected}
              className='multiSelectUser'
            />
          </Col>
        </Row>
      );
    }
  }

  _renderAddUser() {
    return (
      <React.Fragment>
        <Row>
          <h2 className={'pageTitle'}>{i18n.t('userManagement')}</h2>
        </Row>
        <Form>
          <Row>
            <Col sm='8'>
              <TextField
                variant='standard'
                name='email'
                placeholder={i18n.t('addEmail')}
                fullWidth={true}
                label={i18n.t('email')}
                helperText={i18n.t('thisFieldIsRequired')}
                maxLength='255'
                onChange={this._handleInputChange}
                value={this.state.email}
              />
              <br />
            </Col>
            <Col sm='4'>
              <FormControl variant='standard' fullWidth={true}>
                <InputLabel>{i18n.t('accessLevel')}</InputLabel>
                <Select
                  variant='standard'
                  value={this.state.accessLevel}
                  onChange={this._handleChange}
                >
                  {
                    Object.entries(this.state.availableAccessLevels).map(([key, value]) => {
                      return <MenuItem key={key} value={key}>{value}</MenuItem>;
                    })
                  }
                </Select>
              </FormControl>
            </Col>
          </Row>
          {this._renderCitiesSelect()}
          <Row>
            <Col>
              <Button
                variant='contained'
                onClick={this._addUser}
                className='addUserButton'
              >
                {i18n.t('add')}
              </Button>
            </Col>
          </Row>
          <Dialog
            open={this.state.openError}
            onClose={this._handleClose}
            aria-labelledby='user-dialog-title'
            aria-describedby='user-dialog-description'
          >
            <DialogTitle id='user-dialog-title'>{this.state.errorTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText id='user-dialog-description'>
                {this.state.errorMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this._handleClose} color='primary'>
                {'Ok'}
              </Button>
            </DialogActions>
          </Dialog>
        </Form>
        <Row>
          <h2 className={'pageTitle'}>{i18n.t('activeUsers')}</h2>
        </Row>
        {this._renderUsersTable()}
      </React.Fragment>
    );
  }

  render() {
    if (User.getInstance().getUser().access_level === 'super_admin' || User.getInstance().getUser().access_level === 'admin') {
      return (
        <Container className='Users'>
          {this._renderAddUser()}
          {this._renderUserModicationForm()}
          <ToastContainer />
        </Container>
      );
    }
    else {
      return (
        <AccessForbidden />
      );
    }
  }
}

const AccessLevels = {
  admin: i18n.t('administrator'),
  publisher_public_works: i18n.t('publicWorksEditor'),
  publisher_communications: i18n.t('communicationsEditor'),
  publisher_citizens: i18n.t('citizenEditor'),
  super_admin: i18n.t('superAdministrator'),
};

export default Users;
