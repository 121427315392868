import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Alerts from '../scenes/Alerts';
import BrowseProblems from '../scenes/BrowseProblems';
import Invoices from '../scenes/Invoices';
import Surveys from '../scenes/Surveys';
import Informations from '../scenes/Informations';
import Stats from '../scenes/Stats';
import Redirections from '../scenes/Redirections';
import ReportsMessages from '../scenes/ReportsMessages';
import AuthorisedReports from '../scenes/AuthorisedReports';
import Password from '../scenes/Password';
import Users from '../scenes/Users';
import Cities from '../scenes/Cities';
import Citizens from '../scenes/Citizens';
import Subscribers from '../scenes/Subscribers';
import Groups from '../scenes/Groups';
import Parking from '../scenes/Parking';
import SingleProblem from '../scenes/SingleProblem';
import Login from '../scenes/Login';

import i18n from '../i18n';

import { PrivateRoute } from './PrivateRoute';

// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string '/' , marginTop:'76px'
const Main = () => (
  <main style={{ height: '100%', display: 'flex', flexFlow: 'column' }}>
    <Routes>
      <Route exact path={`${process.env.PUBLIC_URL}/`} element={<PrivateRoute component={BrowseProblems} />} />
      <Route path={`${process.env.PUBLIC_URL}/Problem/:id`} element={<PrivateRoute component={SingleProblem} />} />
      <Route path={`${process.env.PUBLIC_URL}/Alerts`} element={<PrivateRoute component={Alerts} />} />
      <Route path={`${process.env.PUBLIC_URL}/Surveys`} element={<PrivateRoute component={Surveys} />} />
      <Route path={`${process.env.PUBLIC_URL}/Portail`} element={<PrivateRoute component={Informations} />} />
      <Route path={`${process.env.PUBLIC_URL}/Parking`} element={<PrivateRoute component={Parking} />} />
      <Route path={`${process.env.PUBLIC_URL}/Stats`} element={<PrivateRoute component={Stats} />} />
      <Route path={`${process.env.PUBLIC_URL}/Users`} element={<PrivateRoute component={Users} />} />
      <Route path={`${process.env.PUBLIC_URL}/Cities`} element={<PrivateRoute component={Cities} />} />
      <Route path={`${process.env.PUBLIC_URL}/Citizens`} element={<PrivateRoute component={Citizens} />} />
      <Route path={`${process.env.PUBLIC_URL}/Subscribers`} element={<PrivateRoute component={Subscribers} />} />
      <Route path={`${process.env.PUBLIC_URL}/Redirections`} element={<PrivateRoute component={Redirections} />} />
      <Route path={`${process.env.PUBLIC_URL}/Password`} element={<PrivateRoute component={Password} />} />
      <Route path={`${process.env.PUBLIC_URL}/ReportsMessages`} element={<PrivateRoute component={ReportsMessages} />} />
      <Route path={`${process.env.PUBLIC_URL}/AuthorisedReports`} element={<PrivateRoute component={AuthorisedReports} />} />
      <Route path={`${process.env.PUBLIC_URL}/Groups`} element={<PrivateRoute component={Groups} />} />
      <Route path={`${process.env.PUBLIC_URL}/Invoices`} element={<PrivateRoute component={Invoices} />} />
      <Route path={`${process.env.PUBLIC_URL}/Login`} element={<Login />} />
      <Route path={'*'} element={<NotFound />} />
    </Routes>
  </main>
);

const NotFound = () =>
  <div>
    <h1>{i18n.t('404Title')}</h1>
    <p>{i18n.t('404Message')}</p>
  </div>;

export default Main;
