import HttpMessage, { ROOT_URL } from './HttpMessage';

import { getHttpCitiesFilter } from '../utils/Filters';
import User from '../data/User';

import { sha512 } from 'js-sha512';

class UsersService {

  static async login(email, password) {
    const hashPassword = sha512.hex(password);
    const message = {
      route: `${ROOT_URL}/users_web/login`,
      method: 'POST',
      body: JSON.stringify({ email: email, password: hashPassword }),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    let user = null;
    if (data.status === 'success') {
      user = data.user;
    }

    return user;
  }

  static async updateToken(navigate) {
    const message = {
      route: `${ROOT_URL}/users_web/token?token=${User.getInstance().getUser().token}`,
      method: 'GET',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    let token = null;
    if (data.status === 'success') {
      token = data.token;
    }
    else {
      navigate('/login');
    }

    return token;
  }

  static async modifyPassword(currentUser) {
    const oldHashPassword = sha512.hex(currentUser.oldPassword);
    const newHashPassword = sha512.hex(currentUser.newPassword);

    const message = {
      route: `${ROOT_URL}/users_web/password?token=${User.getInstance().getUser().token}`,
      method: 'PUT',
      body: JSON.stringify({ old_password: oldHashPassword, new_password: newHashPassword, }),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async requestPassword(email) {
    const message = {
      route: `${ROOT_URL}/users_web/forgotten_password`,
      method: 'POST',
      body: JSON.stringify({ email: email }),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async fetchUsers(cityIds) {
    const citiesFilters = getHttpCitiesFilter(cityIds);

    const message = {
      route: `${ROOT_URL}/users_web?token=${User.getInstance().getUser().token}&${citiesFilters}`,
      method: 'GET',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    const usersList = [];
    if (data.status === 'success') {
      data.users.forEach(val => {
        const obj = {};
        obj.id = val.email;
        obj.email = val.email;
        obj.accessLevel = val.access_level;
        obj.city_ids = val.city_ids;

        usersList.push(obj);
      });
    }

    return usersList;
  }

  static async addUser(user) {
    const message = {
      route: `${ROOT_URL}/users_web?token=${User.getInstance().getUser().token}`,
      method: 'POST',
      body: JSON.stringify(user),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async editUser(user) {
    const message = {
      route: `${ROOT_URL}/users_web/access_level?token=${User.getInstance().getUser().token}`,
      method: 'PUT',
      body: JSON.stringify({ email: user.email, access_level: user.accessLevel }),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async deleteUser(email) {
    const message = {
      route: `${ROOT_URL}/users_web/delete?token=${User.getInstance().getUser().token}`,
      method: 'POST',
      body: JSON.stringify({ email }),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }
}

export default UsersService;
