import React from 'react';
import AsyncComponent from './components/AsyncComponent';
import { useNavigate } from 'react-router-dom';

import Main from './routes/Main';
import User from './data/User';

class App extends AsyncComponent {
  constructor(props) {
    super(props);

    this.state = {
      firstUpdateDone: false,
    };
  }

  async componentDidMount() {
    await this._startPoll();
  }

  async _startPoll() {
    await this._update();
    await this.setStateAsync({ firstUpdateDone: true });
    this.interval = setInterval(async () => await this._update(), 9 * 60 * 1000);
  }

  async _update() {
    await User.getInstance().updateToken(this.props.navigate);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      this.state.firstUpdateDone &&
      <div style={{ height: '100vh' }}>
        <Main />
      </div>
    );
  }
}

function AppPage(props) {
  const navigate = useNavigate();

  return (
    <App navigate={navigate} {...props} />
  );
}

export default AppPage;
