import React from 'react';
import AsyncComponent from '../../components/AsyncComponent';
import { Container, Col, Row } from 'reactstrap';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import AccessForbidden from '../../components/AccessForbidden';
import { ProblemCategories } from '../../utils/Enums';
import ProblemsService from '../../services/ProblemsService';
import User from '../../data/User';
import './styles.scss';
import i18n from '../../i18n';

class AuthorisedReports extends AsyncComponent {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
    };
  }

  async componentDidMount() {
    await this._fetchProblemCategories();
  }

  async _fetchProblemCategories() {
    const categories = await ProblemsService.fetchProblemCategories();
    Object.keys(ProblemCategories).forEach(category => {
      if (!categories.find(catecoryReceived => category === catecoryReceived.name)) {
        categories.push({ name: category, active: false });
      }
    });
    categories.sort((a, b) => ProblemCategories[a.name].name > ProblemCategories[b.name].name ? 1
      : (ProblemCategories[a.name].name < ProblemCategories[b.name].name ? -1 : 0));
    await this.setStateAsync({ categories });
  }

  _notify(message) {
    toast.success(message);
  }

  _notifyError(errorMessage) {
    toast.error(errorMessage);
  }

  async _updateCategoryStatus(category, value) {
    category.active = value;

    const success = await ProblemsService.updateCategoryStatus(category);
    if (success) {
      await this.setStateAsync({ categories: this.state.categories });
      this._notify(i18n.t('successfullyModified'));
    }
    else {
      this._notifyError(i18n.t('errorDuringModification'));
    }
  }

  _renderProblemCategories() {
    return (
      this.state.categories.map(category =>
        <Row key={category.name}>
          <Col sm='2'>
            <h6>{ProblemCategories[category.name].name}</h6>
          </Col>
          <Col sm='4'>
            <FormControl variant='standard' fullWidth={true}>
              <InputLabel>{i18n.t('status')}</InputLabel>
              <Select
                variant='standard'
                value={category.active}
                onChange={async (_event) => await this._updateCategoryStatus(category, _event.target.value)}
              >
                {
                  STATUS.map(function (access) {
                    return <MenuItem key={access.value} value={access.value}>{access.label}</MenuItem>;
                  })
                }
              </Select>
            </FormControl>
          </Col>
        </Row>
      )
    );
  }

  render() {
    if (User.getInstance().getUser().access_level === 'super_admin') {
      return (
        <Container className='AuthorisedReports'>
          <Row>
            <h2 className={'pageTitle'}>{i18n.t('reportCategories')}</h2>
          </Row>
          {this._renderProblemCategories()}
          <ToastContainer />
        </Container>
      );
    }
    else {
      return (
        <AccessForbidden />
      );
    }
  }
}

const STATUS = [
  { label: i18n.t('active'), value: true },
  { label: i18n.t('inactive'), value: false },
];

export default AuthorisedReports;
