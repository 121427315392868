import React from 'react';
import AsyncComponent from '../../components/AsyncComponent';
import Button from '@mui/material/Button';
import { Container, Col, Row } from 'reactstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import UsersService from '../../services/UsersService';
import './styles.scss';
import i18n from '../../i18n';

class Password extends AsyncComponent {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      newPasswordConfirmation: '',
      oldPassword: '',
      open: false,
      openError: false,
      openModificationError: false,
    };

    this._handleClose = this._handleClose.bind(this);
    this._handleInputChange = this._handleInputChange.bind(this);
    this._modifyPassword = this._modifyPassword.bind(this);
  }

  async _handleClose() {
    await this.setStateAsync({
      open: false,
      openCity: false,
      openError: false,
      openCityError: false,
      openModificationError: false,
      openInfoEmailModificationError: false,
    });
  }

  async _handleInputChange(event) {
    const { name, value } = event.target;
    await this.setStateAsync({
      [name]: value
    });
  }

  async _handleModificationPasswordOpenError() {
    await this.setStateAsync({ openModificationError: true });
  }

  async _modifyPassword() {
    if (this.state.newPassword !== '' && this.state.oldPassword !== '' && this.state.newPassword === this.state.newPasswordConfirmation) {
      const success = await UsersService.modifyPassword(this.state);
      success ? this._notify(i18n.t('successfullyModified')) : this._notifyError(i18n.t('errorDuringModification'));
      await this.setStateAsync({
        oldPassword: '',
        newPassword: '',
        newPasswordConfirmation: '',
      });
    }
    else {
      await this._handleModificationPasswordOpenError();
    }
  }

  _notify(message) {
    toast.success(message);
  }

  _notifyError(errorMessage) {
    toast.error(errorMessage);
  }

  _renderPasswordModification() {
    return (
      <React.Fragment>
        <Row>
          <h2 className={'pageTitle'}>{i18n.t('changeMyPassword')}</h2>
        </Row>
        <Row>
          <Col sm='12'>
            <TextField
              variant='standard'
              name='oldPassword'
              placeholder={i18n.t('enterYourCurrentPassword')}
              fullWidth={true}
              label={i18n.t('oldPassword')}
              helperText={i18n.t('thisFieldIsRequired')}
              maxLength='255'
              onChange={this._handleInputChange}
              value={this.state.oldPassword}
              type='password'
            />
            <br />
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <TextField
              variant='standard'
              name='newPassword'
              placeholder={i18n.t('enterYourNewPassword')}
              fullWidth={true}
              label={i18n.t('newPassword')}
              type='password'
              helperText={i18n.t('thisFieldIsRequired')}
              maxLength='255'
              onChange={this._handleInputChange}
              value={this.state.newPassword}
            />
            <br />
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <TextField
              variant='standard'
              type='password'
              name='newPasswordConfirmation'
              placeholder={i18n.t('enterYourNewPassword')}
              fullWidth={true}
              label={i18n.t('newPasswordAgain')}
              helperText={i18n.t('thisFieldIsRequired')}
              maxLength='255'
              onChange={this._handleInputChange}
              value={this.state.newPasswordConfirmation}
            />
            <br />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              variant='contained'
              onClick={this._modifyPassword}
              className='modifyPasswordButton'
            >
              {i18n.t('edit')}
            </Button>
          </Col>
        </Row>
        <Dialog
          open={this.state.openModificationError}
          onClose={this._handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{i18n.t('errorDuringModification')}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {i18n.t('youMustEnterYourOldPasswordAndYourNewPasswordTwice')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this._handleClose} color='primary'>
              {'Ok'}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

  render() {
    return (
      <Container className='Password'>
        {this._renderPasswordModification()}
        <ToastContainer />
      </Container>
    );
  }
}

export default Password;
