import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import React from 'react';
import './styles/index.scss';
import * as serviceWorker from './serviceWorker';
import { LoadScript } from '@react-google-maps/api';
import './i18n';
import { ConfigProvider } from 'antd';

import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';

const theme = createTheme();
const container = document.getElementById('root');
const root = createRoot(container);

root.render((
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <BrowserRouter
        future={{
          v7_startTransition: true,
          v7_relativeSplatPath: true
        }}
      >
        <LoadScript
          id='script-loader'
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_KEY}
          libraries={['drawing', 'geometry', 'places', 'visualization']}
          language='en'
          region='us'
        >
          <ConfigProvider theme={{ hashed: false }}>
            <App />
          </ConfigProvider>
        </LoadScript>
      </BrowserRouter>
    </ThemeProvider>
  </StyledEngineProvider>
));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
