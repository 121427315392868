import React from 'react';
import AsyncComponent from '../../components/AsyncComponent';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { Col, Container, Form, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import deburr from 'lodash/deburr';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import imageCompression from 'browser-image-compression';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment-timezone';
import Multiselect from 'react-select';
import { remove as removeAccents } from 'remove-accents';
import Select from '@mui/material/Select';
import { count as SmsCounter } from 'sms-counter-npm';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TimePicker } from '@mui/x-date-pickers/TimePicker'; import { ToastContainer, toast } from 'react-toastify';
import validator from 'validator';
import 'react-datepicker/dist/react-datepicker.css';

import AccessForbidden from '../../components/AccessForbidden';
import AlertDetails from '../../components/AlertDetails';
import AlertDeliveryStatus from '../../components/AlertDeliveryStatus';
import LoadingPage from '../../components/LoadingPage';
import CommunicationsService from '../../services/CommunicationsService';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import GroupsService from '../../services/GroupsService';
import PolygonMap from '../../components/PolygonMap';
import Table from '../../components/Table';
import { isArrayContainedInOtherArray, orderBy } from '../../utils/array_helper';
import { SupportedImageFileTypes, SupportedImageFileTypesString, NoImageCitySrc } from '../../utils/ImageHelper';
import { DelayPatterns } from '../../utils/Enums';

import User from '../../data/User';
import './styles.scss';
import i18n from '../../i18n';

const invertDirection = {
  asc: 'desc',
  desc: 'asc'
};

class Alert extends AsyncComponent {
  constructor(props) {
    super(props);
    this.state = {
      alerts: [],
      alertDeliveryStatus: null,
      alertDetails: null,
      alertEdited: null,
      alertTypes: {},
      alertToDelete: {},
      anchorEls: {},
      cityToFetch: 0,
      columnToSort: 'dateToSendFormatted',
      content: '',
      date: moment(),
      drawingModeEnabled: false,
      endDate: moment(),
      editing: false,
      errorMessage: '',
      errorTitle: '',
      groups: [],
      groupsSelected: [],
      isAlertRecurrent: false,
      isAlertScheduled: false,
      loading: false,
      moreInformationUrl: '',
      open: false,
      openAlertScheduling: false,
      openAlertTargeting: false,
      openConsult: false,
      openDeliveryStatus: false,
      openError: false,
      openModification: false,
      openSmsConfirmation: false,
      patternCount: 1,
      pattern: 'Weeks',
      pictureBase64: '',
      pictureEditedBase64: null,
      sendToPush: true,
      sendToSms: false,
      sendToVoice: false,
      sendToFacebook: false,
      simulatedSmsHeader: '',
      simulatedVoiceHeader: '',
      smsLength: 0,
      smsPrice: 0.000,
      smsSegments: 0,
      smsUnitPrice: 0.0250,
      sortDirection: 'desc',
      targetMapCenter: { lat: 45.497668, lng: -73.577900 },
      time: moment(),
      title: '',
      type: '',
      userQuery: '',
      userCities: [],
      userCitiesSelected: [],
      value: 1,
      zone: [],
    };

    this._deleteAlert = this._deleteAlert.bind(this);
    this._editNotSupportedReason = this._editNotSupportedReason.bind(this);
    this._handleAddAlertTargetZone = this._handleAddAlertTargetZone.bind(this);
    this._handleDeleteAlertTargetZone = this._handleDeleteAlertTargetZone.bind(this);
    this._handleTitleContentUrlChange = this._handleTitleContentUrlChange.bind(this);
    this._handleChangePattern = this._handleChangePattern.bind(this);
    this._handleChangePatternEdit = this._handleChangePatternEdit.bind(this);
    this._handleChangeIsAlertRecurrent = this._handleChangeIsAlertRecurrent.bind(this);
    this._handleChangeIsAlertScheduled = this._handleChangeIsAlertScheduled.bind(this);
    this._handleChangeEdit = this._handleChangeEdit.bind(this);
    this._handleChangeType = this._handleChangeType.bind(this);
    this._handleClose = this._handleClose.bind(this);
    this._handleCloseAlertModification = this._handleCloseAlertModification.bind(this);
    this._handleCloseAlertScheduling = this._handleCloseAlertScheduling.bind(this);
    this._handleCloseAlertConsultation = this._handleCloseAlertConsultation.bind(this);
    this._handleCloseAlertDeliveryStatus = this._handleCloseAlertDeliveryStatus.bind(this);
    this._handleDateChange = this._handleDateChange.bind(this);
    this._handleEndDateChange = this._handleEndDateChange.bind(this);
    this._handleEndDateChangeEdited = this._handleEndDateChangeEdited.bind(this);
    this._handleDateChangeEdited = this._handleDateChangeEdited.bind(this);
    this._handleDuplicate = this._handleDuplicate.bind(this);
    this._handleGroupsSelect = this._handleGroupsSelect.bind(this);
    this._handleInputChange = this._handleInputChange.bind(this);
    this._handleRemove = this._handleRemove.bind(this);
    this._handleOpenError = this._handleOpenError.bind(this);
    this._handleSelectMultiCitiesChange = this._handleSelectMultiCitiesChange.bind(this);
    this._handleSort = this._handleSort.bind(this);
    this._handleTimeChange = this._handleTimeChange.bind(this);
    this._handleTimeChangeEdited = this._handleTimeChangeEdited.bind(this);
    this._isEditSupported = this._isEditSupported.bind(this);
    this._isStatusSupported = this._isStatusSupported.bind(this);
    this._modifyAlert = this._modifyAlert.bind(this);
    this._onImageFileChange = this._onImageFileChange.bind(this);
    this._onUserQueryChange = this._onUserQueryChange.bind(this);
    this._onZoneCompleted = this._onZoneCompleted.bind(this);
    this._sendAlert = this._sendAlert.bind(this);
    this._toggleConsult = this._toggleConsult.bind(this);
    this._toggleEditing = this._toggleEditing.bind(this);
    this._toggleDeliveryStatus = this._toggleDeliveryStatus.bind(this);
    this._validateAlert = this._validateAlert.bind(this);
  }

  async componentDidMount() {
    await this._getUserCities();
    await this._fetchAlertTypes();
    await this._fetchAlerts();
    await this._fetchGroups();
  }

  async _copyImage(imageUrl) {
    if (imageUrl !== null) {
      const imageBlob = await fetch(imageUrl, { mode: 'cors' }).then(r => r.blob());
      const options = {
        maxSizeMB: 0.9,
        maxWidthOrHeight: 1920
      };
      const compressedPicture = await imageCompression(imageBlob, options);

      const reader = new FileReader();
      reader.readAsDataURL(compressedPicture);
      reader.onloadend = async () => {
        const pictureBase64 = reader.result;
        await this.setStateAsync({ pictureBase64 });
      };
    }
  }

  async _fetchAlerts() {
    const citiesToFetchUsersFrom = this.state.cityToFetch === 0 ? this.state.userCityIds : [this.state.cityToFetch];
    let alerts = await CommunicationsService.fetchAlerts(citiesToFetchUsersFrom);
    alerts = alerts.filter(alert => this.state.cityToFetch === 'null' || this.state.cityToFetch === 0 || alert.group_alert || alert.cityId !== null);

    alerts = alerts.sort((a, b) => {
      const x = a.dateToSend, y = b.dateToSend;

      return x < y ? 1
        : (x > y ? -1 : 0);
    });

    await this.setStateAsync({ alerts });
  }

  async _fetchAlertTypes() {
    const alertTypes = await CommunicationsService.fetchAlertTypes();
    await this.setStateAsync({ alertTypes, type: 'other' });
  }

  async _fetchGroups() {
    const groups = await GroupsService.fetchGroups(this.state.userCityIds);
    groups.forEach(group => {
      delete group.users;
    });

    await this.setStateAsync({ groups });
  }

  async _filterAlerts(cityToFetch) {
    await this.setStateAsync({ cityToFetch });
    await this._fetchAlerts();
  }

  async _getUserCities() {
    const userCities = await User.getInstance().getUserCities();
    const userCityIds = userCities.map(city => city.cityId).filter(cityId => cityId !== 'null');
    await this.setStateAsync({ userCities, userCityIds });

    if (userCities.length === 1) {
      await this.setStateAsync({
        userCitiesSelected: [{ value: userCities[0].cityId, label: userCities[0].name, smsEnabled: userCities[0].smsEnabled, voiceEnabled: userCities[0].voiceEnabled, facebookEnabled: userCities[0].facebookEnabled }],
        cityToFetch: userCities[0].cityId
      });
    }
  }

  async _openAlertTargeting() {
    const response = await geocodeByAddress(this.state.userCities[0].name + ', ' + this.state.userCities[0].state + ', ' + this.state.userCities[0].country);
    const targetMapCenter = await getLatLng(response[0]);

    await this.setStateAsync({ openAlertTargeting: true, targetMapCenter });
  }

  async _onZoneCompleted(zone) {
    if (!this.state.drawingModeEnabled) {
      return;
    }

    await this.setStateAsync({ zone });
  }

  async _handleCloseAlertTargeting(_event, reason) {
    if (reason && reason === 'backdropClick')
      return false;

    await this.setStateAsync({ openAlertTargeting: false, drawingModeEnabled: false });
  }

  async _handleAddAlertTargetZone() {
    await this.setStateAsync({ drawingModeEnabled: true });
  }

  async _handleDeleteAlertTargetZone() {
    await this.setStateAsync({ zone: [], drawingModeEnabled: false });
  }

  async _handleOpenError(errorTitle, errorMessage) {
    await this.setStateAsync({ openError: true, errorTitle, errorMessage });
  }

  async _handleRemove(alert) {
    await this.setStateAsync({ alertToDelete: alert, open: true });
  }

  async _handleClose() {
    await this.setStateAsync({ open: false, openSmsConfirmation: false });
    await this.setStateAsync({ anchorEls: {}, openError: false });
  }

  async _handleCloseAlertScheduling(_event, reason) {
    if (reason && reason === 'backdropClick')
      return false;

    await this.setStateAsync({ openAlertScheduling: false });
  }

  async _handleCloseAlertModification(_event, reason) {
    if (reason && reason === 'backdropClick')
      return false;

    await this.setStateAsync({ openModification: false });
    await this.setStateAsync({ anchorEls: {} });
  }

  async _handleCloseAlertConsultation() {
    await this.setStateAsync({ openConsult: false });
  }

  async _handleCloseAlertDeliveryStatus() {
    await this.setStateAsync({ alertDeliveryStatus: null, openDeliveryStatus: false });
  }

  async _handleInputChange(event) {
    const { name, value } = event.target;
    await this.setStateAsync({
      [name]: value
    });
  }

  async _handleTitleContentUrlChange(event) {
    await this._handleInputChange(event);
    await this._updateSmsInformations();
  }

  async _handleChangeEdit(event) {
    this.state.alertEdited.type = event.target.value;
    await this.forceUpdateAsync();
  }

  async _handleChangePatternEdit(event) {
    this.state.alertEdited.pattern = event.target.value;
    await this.forceUpdateAsync();
  }

  async _handleChangeIsAlertRecurrent(event) {
    this.setStateAsync({ isAlertRecurrent: event.target.value });
  }

  async _handleChangeIsAlertScheduled(event) {
    this.setStateAsync({ isAlertScheduled: event.target.value });
  }

  async _handleChangePattern(event) {
    this.setStateAsync({ pattern: event.target.value });
  }

  async _handleChangeType(event) {
    await this.setStateAsync({ type: event.target.value });
  }

  async _handleDateChange(date) {
    await this.setStateAsync({ date: moment(date) });
  }

  async _handleEndDateChange(date) {
    await this.setStateAsync({ endDate: moment(date) });
  }

  async _handleTimeChange(time) {
    await this.setStateAsync({ time });
  }

  async _handleDateChangeEdited(dateEdited) {
    this.state.alertEdited.dateToSend = moment(dateEdited);
    await this.forceUpdateAsync();
  }

  async _handleEndDateChangeEdited(dateEdited) {
    this.state.alertEdited.endDate = moment(dateEdited);
    await this.forceUpdateAsync();
  }

  async _handleTimeChangeEdited(timeEdited) {
    this.state.alertEdited.timeToSend = timeEdited;
    await this.forceUpdateAsync();
  }

  _isEditSupported(alert) {
    return (alert.push || !alert.sent)
      && (User.getInstance().getUser().access_level === 'super_admin' || isArrayContainedInOtherArray(alert.cityIds, this.state.userCityIds));
  }

  _isStatusSupported(alert) {
    return (alert.sms && alert.sent) || (User.getInstance().getUser().access_level === 'super_admin');
  }

  async _handleGroupsSelect(groups) {
    await this.setStateAsync({ groupsSelected: groups ? groups : [] });
  }

  async _handleSelectMultiCitiesChange(cities) {
    const smsEnabled = cities && cities.some(city => city.smsEnabled);
    const voiceEnabled = cities && cities.some(city => city.voiceEnabled);
    const facebookEnabled = cities && cities.some(city => city.facebookEnabled);

    const pushNotificationMustBeEnabled = !smsEnabled && !voiceEnabled;

    const longestCityName = cities && cities.length > 0 ? removeAccents(cities.reduce((prev, current) => prev.label.length > current.label.length ? prev : current).label.toUpperCase()) : '';
    const simulatedSmsHeader = '[ ALERTE CARECITY ]\n[ VILLE DE ' + longestCityName + ' ]\n';
    const simulatedVoiceHeader = 'Message de la part de caire city pour la ville de ' + longestCityName + ' : ';

    await this.setStateAsync({ userCitiesSelected: cities ? cities : [], sendToPush: this.state.sendToPush || pushNotificationMustBeEnabled, sendToSms: this.state.sendToSms && smsEnabled, sendToVoice: this.state.sendToVoice && voiceEnabled, sendToFacebook: this.state.sendToFacebook && facebookEnabled, simulatedSmsHeader, simulatedVoiceHeader });
    await this._updateSmsInformations();
  }

  async _onImageFileChange(event) {
    event.preventDefault();
    const files = event.target.files;
    if (files.length !== 0 && SupportedImageFileTypes.includes(files[0].type.replace('image/', ''))) {
      const options = {
        maxSizeMB: 0.9,
        maxWidthOrHeight: 1920
      };
      const compressedPicture = await imageCompression(files[0], options);

      const reader = new FileReader();
      reader.readAsDataURL(compressedPicture);
      reader.onloadend = async () => {
        const pictureBase64 = reader.result;
        await this.setStateAsync({ pictureBase64 });
      };
    }
    else {
      this._notifyError(i18n.t('theSelectedFileIsNotInASupportedFormat'));
    }
  }

  async _setAnchorEl(alertId, anchorEl) {
    const anchorEls = this.state.anchorEls;
    anchorEls[alertId] = anchorEl;
    await this.setStateAsync({ anchorEls });
  }

  _notifySuccess(message) {
    toast.success(message);
  }

  _notifyError(message) {
    toast.error(message);
  }

  async _updateSmsInformations() {
    const sms = SmsCounter(this.state.simulatedSmsHeader + this.state.title.toUpperCase() + '\n' + this.state.content + '\n' + this.state.moreInformationUrl);
    await this.setStateAsync({ smsSegments: sms.messages, smsLength: sms.length });
  }

  async _updateSmsInformationsEditing() {
    const sms = SmsCounter(this.state.simulatedSmsHeaderEdited + this.state.alertEdited.title.toUpperCase() + '\n' + this.state.alertEdited.content + '\n' + this.state.moreInformationUrl);
    this.state.alertEdited.smsSegmentsEdited = sms.messages;
    this.state.alertEdited.smsLengthEdited = sms.length;

    this.forceUpdate();
  }

  async _onUserQueryChange(e) {
    await this.setStateAsync({ userQuery: e.target.value });
  }

  async _handleSort(columnName) {
    await this.setStateAsync(state => ({
      columnToSort: columnName,
      sortDirection:
        state.columnToSort === columnName
          ? invertDirection[state.sortDirection]
          : 'asc'
    }));
  }

  async _cancelEditing(alert) {
    await this._setAnchorEl(alert.id, null);
    alert.alertEditingEnabled = false;
    await this.setStateAsync({
      editing: false,
      openModification: false,
      alertEdited: null,
      alerts: this.state.alerts,
      pictureEditedBase64: null,
    });
  }

  async _toggleDeliveryStatus(alert) {
    await this.setStateAsync({ loading: true });

    const alertDeliveryStatus = await CommunicationsService.fetchAlertDeliveryStatus(alert);

    await this.setStateAsync({
      alertDeliveryStatus,
      loading: false,
      openDeliveryStatus: true
    });
  }

  async _toggleEditing(alert) {
    alert.alertEditingEnabled = true;

    const alertEdited = JSON.parse(JSON.stringify(alert));
    alertEdited.imagePreview = alertEdited.pictureUrl !== null ? alertEdited.pictureUrl : '';
    alertEdited.moreInformationUrl = alertEdited.moreInformationUrl !== null ? alertEdited.moreInformationUrl : '';
    alertEdited.dateToSend = moment(alertEdited.dateToSend);

    const longestCityName = removeAccents(alert.citiesName.split(', ').reduce((prev, current) => prev.length > current.length ? prev : current).toUpperCase());
    const simulatedSmsHeaderEdited = '[ ALERTE CARECITY ]\n[ VILLE DE ' + longestCityName + ' ]\n';
    const simulatedVoiceHeaderEdited = 'Message de la part de caire city pour la ville de ' + longestCityName + ' : ';

    alertEdited.facebookSupported = false;
    alertEdited.smsSupported = false;
    alertEdited.voiceSupported = false;

    alertEdited.facebookSupported = alertEdited.cityIds.some(cityId => {
      const city = this.state.userCities.find(city => cityId === city.cityId);
      return city && city.facebookEnabled;
    });
    alertEdited.smsSupported = alertEdited.cityIds.some(cityId => {
      const city = this.state.userCities.find(city => cityId === city.cityId);
      return city && city.smsEnabled;
    });
    alertEdited.voiceSupported = alertEdited.cityIds.some(cityId => {
      const city = this.state.userCities.find(city => cityId === city.cityId);
      return city && city.voiceEnabled;
    });

    if (alertEdited.pattern) {
      alertEdited.endDate = moment(alertEdited.endDate);
    }

    await this.setStateAsync({
      alertEdited,
      editing: true,
      openModification: true,
      simulatedSmsHeaderEdited,
      simulatedVoiceHeaderEdited,
    });

    await this._updateSmsInformationsEditing();
  }

  async _handleDuplicate(alert) {
    await this.setStateAsync({
      title: alert.title,
      content: alert.content,
      loading: true,
      type: alert.type,
      moreInformationUrl: alert.moreInformationUrl !== null ? alert.moreInformationUrl : '',
    });

    await this._copyImage(alert.pictureUrl);

    await this.setStateAsync({ loading: false });
    toast.success(i18n.t('alertDuplicated'));
  }

  async _toggleConsult(alertDetails) {
    await this.setStateAsync({
      alertDetails,
      openConsult: true,
    });
  }

  async _toggleSendToPush(sendToPush) {
    await this.setStateAsync({ sendToPush });
  }

  async _toggleSendToSms(sendToSms) {
    await this.setStateAsync({ sendToSms });
  }

  async _toggleSendToVoice(sendToVoice) {
    await this.setStateAsync({ sendToVoice });
  }

  async _toggleSendToFacebook(sendToFacebook) {
    await this.setStateAsync({ sendToFacebook });
  }

  async _validateAlert() {
    if (!this.state.loading) {
      const voiceLength = (this.state.simulatedVoiceHeader + this.state.title + ' . ' + this.state.content).length;

      if (this.state.title === '') {
        await this._handleOpenError(i18n.t('errorDuringCreation'), i18n.t('youMustAddATitle'));
      }
      else if (this.state.content === '') {
        await this._handleOpenError(i18n.t('errorDuringCreation'), i18n.t('youMustAddAContent'));
      }
      else if (this.state.moreInformationUrl !== '' && !validator.isURL(this.state.moreInformationUrl, { protocols: ['http', 'https'], require_protocol: true })) {
        await this._handleOpenError(i18n.t('errorDuringCreation'), i18n.t('yourUrlLinkIsInvalid'));
      }
      else if (this.state.userCitiesSelected.length === 0 && this.state.groupsSelected.length === 0) {
        await this._handleOpenError(i18n.t('errorDuringCreation'), i18n.t('youMustChooseAtLeastOneCityOrGroup'));
      }
      else if (!this.state.sendToPush && !this.state.sendToSms && !this.state.sendToVoice && !this.state.sendToFacebook) {
        await this._handleOpenError(i18n.t('errorDuringCreation'), i18n.t('youMustChooseAtLeastOneSendingMethod'));
      }
      else if (this.state.sendToSms && this.state.smsLength > 1600) {
        await this._handleOpenError(i18n.t('errorDuringCreation'), i18n.t('youMustHaveAContentWithLessThan1600CharactersForSmsAlerts', { nbCharacters: this.state.smsLength }));
      }
      else if (this.state.sendToVoice && voiceLength > 4096) {
        await this._handleOpenError(i18n.t('errorDuringCreation'), i18n.t('youMustHaveAContentWithLessThan4096CharactersForVoiceAlerts', { nbCharacters: voiceLength }));
      }
      else if (!this.state.openSmsConfirmation && this.state.sendToSms) {
        const smsPrice = parseFloat(this.state.smsSegments * this.state.smsUnitPrice).toFixed(3);
        await this.setStateAsync({ openSmsConfirmation: true, smsPrice });
      }
      else {
        await this._sendAlert();
      }
    }
  }

  async _sendAlert() {
    await this.setStateAsync({ loading: true, openSmsConfirmation: false });

    const alert = {
      title: this.state.title,
      content: this.state.content,
      picture_base64: this.state.pictureBase64,
      more_information_url: this.state.moreInformationUrl,
      city_ids: this.state.userCitiesSelected.map(city => city.value === 'null' ? null : city.value),
      groups: this.state.groupsSelected.map(group => group.value),
      time_to_send: null,
      type: this.state.type,
      push: this.state.sendToPush,
      sms: this.state.sendToSms,
      voice: this.state.sendToVoice,
      facebook: this.state.sendToFacebook,
    };

    if (this.state.isAlertScheduled) {
      alert.time_to_send = moment(this.state.date.format('YYYY-MM-DD') + ' ' + this.state.time.format('HH:mm')).utc().format();

      if (this.state.isAlertRecurrent) {
        alert.pattern = this.state.pattern;
        alert.pattern_count = this.state.patternCount;
        alert.end_datetime = moment(this.state.endDate.format('YYYY-MM-DD') + ' ' + this.state.time.format('HH:mm')).utc().format();
      }
    }

    if (this.state.groupsSelected.length === 0 && this.state.zone.length > 2) {
      const zones = [];
      zones.push(this.state.zone);
      alert.zones = zones;
    }

    const success = await CommunicationsService.sendAlert(alert);

    if (success) {
      await this._fetchAlerts();
      await this.setStateAsync({
        alertEdited: null,
        content: '',
        date: moment(),
        editing: false,
        endDate: moment(),
        groupsSelected: [],
        isAlertRecurrent: false,
        isAlertScheduled: false,
        moreInformationUrl: '',
        pattern: 'Weeks',
        patternCount: 1,
        pictureBase64: '',
        sendToFacebook: false,
        sendToPush: true,
        sendToSms: false,
        sendToVoice: false,
        time: moment(),
        title: '',
        type: 'other',
        userCitiesSelected: [],
        zone: [],
      });

      await this._updateSmsInformations();

      if (this.state.userCities.length === 1) {
        await this.setStateAsync({ userCitiesSelected: [{ value: this.state.userCities[0].cityId, label: this.state.userCities[0].name }] });
      }

      this._notifySuccess(i18n.t('successfullyCreated'));
    }
    else {
      this._notifyError(i18n.t('errorDuringCreation'));
    }

    await this.setStateAsync({ loading: false });
  }

  async _validateAlertModification() {
    const alertEdited = this.state.alertEdited;
    const smsLength = SmsCounter(this.state.simulatedSmsHeaderEdited + alertEdited.title.toUpperCase() + '\n' + alertEdited.content).length;
    const voiceLength = (this.state.simulatedVoiceHeaderEdited + alertEdited.title + ' . ' + alertEdited.content).length;

    if (!this.state.loading) {
      if (alertEdited.title === '') {
        await this._handleOpenError(i18n.t('errorDuringModification'), i18n.t('youMustAddATitle'));
      }
      else if (alertEdited.content === '') {
        await this._handleOpenError(i18n.t('errorDuringModification'), i18n.t('youMustAddAContent'));
      }
      else if (alertEdited.moreInformationUrl && !validator.isURL(alertEdited.moreInformationUrl, { protocols: ['http', 'https'], require_protocol: true })) {
        await this._handleOpenError(i18n.t('errorDuringModification'), i18n.t('yourUrlLinkIsInvalid'));
      }
      else if (!alertEdited.push && !alertEdited.sms && !alertEdited.voice && !alertEdited.facebook) {
        await this._handleOpenError(i18n.t('errorDuringModification'), i18n.t('youMustChooseAtLeastOneSendingMethod'));
      }
      else if (!alertEdited.sent && alertEdited.sms && smsLength > 1600) {
        await this._handleOpenError(i18n.t('errorDuringModification'), i18n.t('youMustHaveAContentWithLessThan1600CharactersForSmsAlerts', { nbCharacters: smsLength }));
      }
      else if (!alertEdited.sent && alertEdited.voice && voiceLength > 4096) {
        await this._handleOpenError(i18n.t('errorDuringModification'), i18n.t('youMustHaveAContentWithLessThan4096CharactersForVoiceAlerts', { nbCharacters: voiceLength }));
      }
      else if (!alertEdited.sent && !this.state.openSmsConfirmation && alertEdited.sms) {
        const smsPrice = parseFloat(alertEdited.smsSegmentsEdited * this.state.smsUnitPrice).toFixed(3);
        await this.setStateAsync({ openSmsConfirmation: true, smsPrice });
      }
      else {
        await this._modifyAlert();
      }
    }
  }

  async _modifyAlert() {
    const alertEdited = this.state.alertEdited;

    await this.setStateAsync({ loading: true });

    const alert = {
      id: alertEdited.id,
      title: alertEdited.title,
      type: alertEdited.type,
      content: alertEdited.content,
      more_information_url: alertEdited.moreInformationUrl ? alertEdited.moreInformationUrl : null,
      picture_url: alertEdited.pictureUrl !== null && alertEdited.pictureUrl !== 'null' && alertEdited.pictureUrl !== '' ? alertEdited.pictureUrl : null,
      facebook: alertEdited.facebook,
      push: alertEdited.push,
      sms: alertEdited.sms,
      voice: alertEdited.voice,
    };

    if (this.state.pictureEditedBase64 !== null) {
      alert.picture_base64 = this.state.pictureEditedBase64;
    }

    if (!alertEdited.sent) {
      alert.time_to_send = moment(alertEdited.dateToSend.format('YYYY-MM-DD') + ' ' + alertEdited.timeToSend.format('HH:mm')).utc().format();

      if (alertEdited.pattern) {
        alert.pattern = alertEdited.pattern;
        alert.pattern_count = alertEdited.patternCount;
        alert.end_datetime = moment(alertEdited.endDate).utc().format();
      }
    }

    const success = await CommunicationsService.editAlert(alert);

    if (success) {
      this._notifySuccess(i18n.t('successfullyModified'));
      await this._fetchAlerts();

      await this.setStateAsync({
        alertEdited: null,
        editing: false,
        pictureEditedBase64: null,
        openSmsConfirmation: false,
        openModification: false
      });
    }
    else {
      this._notifyError(i18n.t('errorDuringModification'));
    }

    await this.setStateAsync({ loading: false });
  }

  async _deleteAlert() {
    const success = await CommunicationsService.deleteAlert(this.state.alertToDelete.id);
    if (success) {
      await this.setStateAsync({ open: false });
      this._notifySuccess(i18n.t('successfullyRemoved'));
      await this.setStateAsync({ anchorEls: {}, alertToDelete: null, editing: false });
      await this._fetchAlerts();
    }
    else {
      this._notifyError(i18n.t('errorDuringDeletion'));
    }
  }

  _editNotSupportedReason(alert) {
    let reason = i18n.t('cannotEditThisAlert');
    if (!alert.push && alert.sent) {
      reason = i18n.t('anSmsOrAVoiceCallCannotBeChangedOnceSent');
    }
    else if (!isArrayContainedInOtherArray(alert.cityIds, this.state.userCityIds)) {
      reason = i18n.t('youDoNotHaveAccessToAllCitiesInThisAlert');
    }

    return reason;
  }

  async _removePicture() {
    this.alertEditedImageInput.value = '';
    this.state.alertEdited.imagePreview = '';
    await this.setStateAsync({ pictureEditedBase64: '' });
  }

  _renderAlertTargeting() {
    return (
      <Dialog
        open={this.state.openAlertTargeting}
        onClose={this._handleCloseAlertTargeting}
        maxWidth='lg'
        fullWidth={true}
      >
        <h3 className='alertSchedulingTitle'>{i18n.t('targetAlertTitle')}</h3>
        <DialogContent>
          <Row>
            <Col sm='12'>
              <PolygonMap onZoneCompleted={this._onZoneCompleted} currentZone={this.state.zone} mapCenter={this.state.targetMapCenter} drawingModeEnabled={this.state.drawingModeEnabled} />
            </Col>
          </Row>
          {
            this.state.zone.length === 0 && !this.state.drawingModeEnabled &&
            <Row className='alertTargetingButtonsContainer'>
              <Button
                variant='outlined'
                onClick={async () => await this._handleAddAlertTargetZone()}
                className='alertTargetingAddZoneButton'
              >
                {i18n.t('createZone')}
              </Button>
            </Row>
          }
          {
            (this.state.zone.length !== 0 || this.state.drawingModeEnabled) &&
            <Row className='alertTargetingButtonsContainer'>
              <Button
                variant='outlined'
                onClick={async () => await this._handleDeleteAlertTargetZone()}
                className='alertTargetingDeleteZoneButton'
              >
                {i18n.t('deleteZone')}
              </Button>
            </Row>
          }
        </DialogContent>
        <DialogActions className='editAlertTargetingButtonsContainer'>
          <Button
            variant='outlined'
            onClick={async () => await this._handleCloseAlertTargeting()}
            className='alertSchedulingConfirmButton'
          >
            {i18n.t('save')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _renderDialogs() {
    return (
      <React.Fragment>
        <Dialog
          open={this.state.open}
          onClose={this._handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{i18n.t('confirmationOfDeletion')}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {i18n.t('areYouSureYouWantToDeleteThis')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this._handleClose} color='primary'>
              {i18n.t('cancel')}
            </Button>
            <Button onClick={this._deleteAlert} color='primary'>
              {i18n.t('confirm')}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openError}
          onClose={this._handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{this.state.errorTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {this.state.errorMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this._handleClose} color='primary'>
              {'Ok'}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openSmsConfirmation}
          onClose={this._handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{i18n.t('confirmationOfAlertDeliveryViaSms')}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {i18n.t('yourAlertWillCostXPerCitizenSubscribedToSmsAlerts', { smsPrice: this.state.smsPrice })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this._handleClose} color='primary'>
              {i18n.t('cancel')}
            </Button>
            <Button onClick={this.state.editing ? this._modifyAlert : this._sendAlert} color='primary'>
              {i18n.t('confirm')}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

  _renderImagePreview() {
    if (this.state.pictureBase64 !== '') {
      return (
        <img
          src={this.state.pictureBase64}
          className='createAlertImagePreview'
          alt={i18n.t('alert')}
        />
      );
    }
    else {
      return null;
    }
  }

  _renderCommunicationsChannelsCheckbox() {
    const facebookEnabled = this.state.userCitiesSelected.some(city => city.facebookEnabled);
    const smsEnabled = this.state.userCitiesSelected.some(city => city.smsEnabled);
    const voiceEnabled = this.state.userCitiesSelected.some(city => city.voiceEnabled);

    return (
      <React.Fragment>
        {
          (smsEnabled || voiceEnabled || facebookEnabled) &&
          <div className='communicationChannelsContainer'>
            <React.Fragment>
              {
                (smsEnabled || voiceEnabled || facebookEnabled) &&
                <React.Fragment>
                  <Row>
                    <Col sm={12}>
                      <h3 className={'pageSecondTitle'}>{i18n.t('communicationChannels')}</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm='5' className='categoryCheckbox'>
                      <h5 className='communicationChannelsLabel'>{i18n.t('sendTheAlertViaCarecityPushNotification')}</h5>
                    </Col>
                    <Col sm='1' >
                      <Checkbox
                        checked={this.state.sendToPush}
                        color='default'
                        onChange={async (_, value) => await this._toggleSendToPush(value)}
                      />
                    </Col>
                  </Row>
                </React.Fragment>
              }
            </React.Fragment>
            {
              facebookEnabled &&
              <Row>
                <Col sm='5' className='categoryCheckbox'>
                  <h5 className='communicationChannelsLabel'>{i18n.t('sendTheAlertOnAssociatedFacebookPage')}</h5>
                </Col>
                <Col sm='1' >
                  <Checkbox
                    checked={this.state.sendToFacebook}
                    color='default'
                    onChange={async (_, value) => await this._toggleSendToFacebook(value)}
                  />
                </Col>
              </Row>
            }
            {
              smsEnabled &&
              <Row>
                <Col sm='5' className='categoryCheckbox'>
                  <h5 className='communicationChannelsLabel'>{i18n.t('sendTheAlertViaSms')}</h5>
                </Col>
                <Col sm='1' >
                  <Checkbox
                    checked={this.state.sendToSms}
                    color='default'
                    onChange={async (_, value) => await this._toggleSendToSms(value)}
                  />
                </Col>
              </Row>
            }
            {this.state.sendToSms && <p className='smsCounter'>{i18n.t('numberOfSmsCharacters', { smsLength: this.state.smsLength })}</p>}
            {this.state.sendToSms && <p className='smsCounter'>{i18n.t('numberOfSmsSegments', { smsSegments: this.state.smsSegments })}</p>}
            {
              voiceEnabled &&
              <Row>
                <Col sm='5' className='categoryCheckbox'>
                  <h5 className='communicationChannelsLabel'>{i18n.t('sendTheAlertViaVoiceCall')}</h5>
                </Col>
                <Col sm='1' >
                  <Checkbox
                    checked={this.state.sendToVoice}
                    color='default'
                    onChange={async (_, value) => await this._toggleSendToVoice(value)}
                  />
                </Col>
              </Row>
            }
          </div>
        }
      </React.Fragment>
    );
  }

  _renderAlertScheduling() {
    return (
      <Dialog
        open={this.state.openAlertScheduling}
        onClose={this._handleCloseAlertScheduling}
        maxWidth='md'
        fullWidth={true}
      >
        <h3 className='alertSchedulingTitle'>{i18n.t('scheduleTheAlert')}</h3>
        <DialogContent>
          <form>
            <Row>
              <Col sm='2'>
                <FormControl variant='standard' className='alertSchedulingRecurrentSelector'>
                  <InputLabel>{i18n.t('programmed')}</InputLabel>
                  <Select
                    variant='standard'
                    value={this.state.isAlertScheduled}
                    onChange={this._handleChangeIsAlertScheduled}
                  >
                    <MenuItem key={1} value={false}>{i18n.t('no')}</MenuItem>
                    <MenuItem key={2} value={true}>{i18n.t('yes')}</MenuItem>
                  </Select>
                </FormControl>
              </Col>
              {this.state.isAlertScheduled &&
                <React.Fragment>
                  <Col sm='3'>
                    <h6>{i18n.t('dispatchDate')}</h6>
                    <DatePicker
                      selected={this.state.date.toDate()}
                      onChange={this._handleDateChange}
                      minDate={moment().toDate()}
                      popperPlacement={'top'}
                      popperModifiers={{
                        flip: {
                          behavior: ['top']
                        },
                        preventOverflow: {
                          enabled: true
                        },
                        hide: {
                          enabled: false
                        }
                      }}
                    />
                  </Col>
                  <Col sm='3'>
                    <h6>{i18n.t('dispatchTime')}</h6>
                    <TimePicker
                      sx={{p: 0}}
                      onChange={this._handleTimeChange}
                      value={this.state.time}
                    />
                  </Col>
                </React.Fragment>
              }
            </Row>
            {this.state.isAlertScheduled &&
              <Row className='alertSchedulingRecurrentSelectorContainer'>
                <Col sm='2'>
                  <FormControl variant='standard' className='alertSchedulingRecurrentSelector'>
                    <InputLabel>{i18n.t('recurrence')}</InputLabel>
                    <Select
                      variant='standard'
                      value={this.state.isAlertRecurrent}
                      onChange={this._handleChangeIsAlertRecurrent}
                    >
                      <MenuItem key={1} value={false}>{i18n.t('no')}</MenuItem>
                      <MenuItem key={2} value={true}>{i18n.t('yes')}</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
                {this.state.isAlertRecurrent &&
                  <React.Fragment>
                    <Col sm='2'>
                      <TextField
                        variant='standard'
                        type='number'
                        inputProps={{ min: 1 }}
                        name='patternCount'
                        placeholder={i18n.t('periodicity')}
                        fullWidth={true}
                        label={i18n.t('periodicity')}
                        helperText={i18n.t('thisFieldIsRequired')}
                        onChange={this._handleInputChange}
                        value={this.state.patternCount}
                        error={this.state.patternCount < 1}
                      />
                    </Col>
                    <Col sm='4'>
                      <FormControl variant='standard' className='alertSchedulingRecurrentSelector'>
                        <InputLabel>{i18n.t('repeatability')}</InputLabel>
                        <Select
                          variant='standard'
                          value={this.state.pattern}
                          onChange={this._handleChangePattern}
                        >
                          {
                            Object.keys(DelayPatterns).map((pattern) =>
                              <MenuItem key={pattern} value={pattern}>{DelayPatterns[pattern].name}</MenuItem>
                            )
                          }
                        </Select>
                      </FormControl>
                    </Col>
                    <Col sm='4'>
                      <h6>{i18n.t('endDateOfRecurrence')}</h6>
                      <DatePicker
                        selected={this.state.endDate.toDate()}
                        onChange={this._handleEndDateChange}
                        minDate={moment().toDate()}
                        popperPlacement={'top'}
                        popperModifiers={{
                          flip: {
                            behavior: ['bottom']
                          },
                          preventOverflow: {
                            enabled: true
                          },
                          hide: {
                            enabled: false
                          }
                        }}
                      />
                    </Col>
                  </React.Fragment>
                }
              </Row>
            }
          </form>
        </DialogContent>
        <DialogActions className='editAlertButtonsContainer'>
          <Button
            variant='outlined'
            onClick={async () => await this._handleCloseAlertScheduling()}
            className={!this.state.isAlertRecurrent || this.state.patternCount > 0 ? 'alertSchedulingConfirmButton' : 'alertSchedulingConfirmButtonDisabled'}
            disabled={this.state.isAlertRecurrent && this.state.patternCount < 1}
          >
            {i18n.t('save')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _renderAlertDetails() {
    if (this.state.alertDetails !== null) {
      return (
        <Dialog
          open={this.state.openConsult}
          onClose={this._handleCloseAlertConsultation}
          maxWidth='md'
          fullWidth={true}
        >
          <h3 className='consultAlertTitle'>{i18n.t('alertDetailsHeader')}</h3>
          <DialogContent>
            <form>
              <AlertDetails alert={this.state.alertDetails} alertTypes={this.state.alertTypes} userCityIds={this.state.userCityIds} />
            </form>
          </DialogContent>
          <DialogActions className='editAlertButtonsContainer'>
            <Button
              variant='outlined'
              onClick={async () => await this._handleCloseAlertConsultation()}
              className='editAlertCancelButton'
            >
              {'OK'}
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  }

  _renderAlertDeliveryStatus() {
    if (this.state.alertDeliveryStatus !== null) {
      return (
        <Dialog
          open={this.state.openDeliveryStatus}
          onClose={this._handleCloseAlertDeliveryStatus}
          maxWidth='md'
          fullWidth={true}
        >
          <h3 className='consultAlertTitle'>{i18n.t('deliveryStatus')}</h3>
          <DialogContent>
            <AlertDeliveryStatus alertDeliveryStatus={this.state.alertDeliveryStatus} />
          </DialogContent>
          <DialogActions className='editAlertButtonsContainer'>
            <Button
              variant='outlined'
              onClick={async () => await this._handleCloseAlertDeliveryStatus()}
              className='editAlertCancelButton'
            >
              {'OK'}
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  }

  _renderAlertModicationForm() {
    const languageEnabled = i18n.getLanguageEnabled();
    if (this.state.alertEdited !== null) {
      return (
        <Dialog
          open={this.state.openModification}
          onClose={this._handleCloseAlertModification}
          maxWidth='md'
          fullWidth={true}
        >
          <h3 className='editAlertTitle'>{i18n.t('alertEdit')}</h3>
          <DialogContent>
            <form>
              <Row>
                <Col xs={12} sm={12} md={4} lg={4}>
                  <div className='editAlertImageContainer'>
                    <img
                      src={this.state.alertEdited.imagePreview === '' ? NoImageCitySrc : this.state.alertEdited.imagePreview}
                      className='imagePreview'
                      alt={i18n.t('alert')}
                    />
                    <Row className='editAlertImageButtonsContainer'>
                      <label htmlFor='contained-button-file2' className='editAlertModifyImageButtonLabel'>
                        <Button variant='outlined' component='span'
                          className='editAlertModifyImageButton'
                        >
                          {this.state.alertEdited.imagePreview ? i18n.t('editTheImage') : i18n.t('addAnImage')}
                        </Button>
                      </label>
                      {(this.state.alertEdited.imagePreview !== '') &&
                        <div className='deletePictureButtonContainer'>
                          <IconButton
                            aria-label='Delete'
                            onClick={async () => await this._removePicture()}
                            size='large'>
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      }
                    </Row>
                    <Row>
                      <div className={(this.state.alertEdited.imagePreview !== '') ? 'editPictureButtonContainerWithDelete' : 'editPictureButtonContainer'}>
                        <input
                          accept={SupportedImageFileTypesString}
                          id='contained-button-file2'
                          type='file'
                          className='imageInput'
                          ref={alertEditedImageInput => this.alertEditedImageInput = alertEditedImageInput}
                          onChange={
                            async (e) => {
                              e.preventDefault();
                              const files = e.target.files;
                              if (files.length !== 0 && SupportedImageFileTypes.includes(files[0].type.replace('image/', ''))) {
                                await this.setStateAsync({ loading: true });
                                const options = {
                                  maxSizeMB: 0.9,
                                  maxWidthOrHeight: 1920
                                };
                                const compressedPicture = await imageCompression(files[0], options);

                                const reader = new FileReader();
                                reader.readAsDataURL(compressedPicture);
                                reader.onloadend = async () => {
                                  const pictureEditedBase64 = reader.result;
                                  this.state.alertEdited.imagePreview = pictureEditedBase64;
                                  await this.setStateAsync({ loading: false, pictureEditedBase64 });
                                };
                              }
                              else {
                                this._notifyError(i18n.t('theSelectedFileIsNotInASupportedFormat'));
                              }
                            }
                          }
                        />
                      </div>
                      <br />
                    </Row>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={8} lg={8}>
                  <Row className='editAlertFieldContainer'>
                    <TextField
                      className='editAlertTextField'
                      variant='standard'
                      name='titleEdited'
                      placeholder={i18n.t('addTitle')}
                      fullWidth={true}
                      label={i18n.t('title')}
                      helperText={i18n.t('thisFieldIsRequired')}
                      multiline={true}
                      onChange={async (event) => {
                        this.state.alertEdited.title = event.target.value;
                        await this._updateSmsInformationsEditing();
                      }}
                      value={this.state.alertEdited.title}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Row>
                  <Row className='editAlertFieldContainer'>
                    <FormControl
                      className='editAlertTextField'
                      variant='standard'
                      fullWidth={true}
                    >
                      <InputLabel>{'Type'}</InputLabel>
                      <Select
                        variant='standard'
                        value={this.state.alertEdited.type}
                        onChange={this._handleChangeEdit}
                      >
                        {
                          Object.keys(this.state.alertTypes).sort((a, b) => {
                            return this.state.alertTypes[a][languageEnabled] > this.state.alertTypes[b][languageEnabled] ? 1
                              : (this.state.alertTypes[a][languageEnabled] < this.state.alertTypes[b][languageEnabled] ? -1 : 0);
                          }).map((type) => {
                            if (type !== 'night_parking') {
                              return <MenuItem key={type} value={type}>{this.state.alertTypes[type][languageEnabled]}</MenuItem>;
                            }
                            return null;
                          })
                        }
                      </Select>
                    </FormControl>
                  </Row>
                  <Row className='editAlertFieldContainer'>
                    <TextField
                      className='editAlertTextField'
                      variant='standard'
                      name='contentEdited'
                      placeholder={i18n.t('addContent')}
                      fullWidth={true}
                      label={i18n.t('content')}
                      helperText={i18n.t('thisFieldIsRequired')}
                      multiline={true}
                      onChange={async (event) => {
                        this.state.alertEdited.content = event.target.value;
                        await this._updateSmsInformationsEditing();
                      }}
                      value={this.state.alertEdited.content}
                    />
                  </Row>
                  <Row className='editAlertFieldContainer'>
                    <TextField
                      className='editAlertTextField'
                      variant='standard'
                      name='moreInformationUrlEdited'
                      placeholder={i18n.t('addUrlLink')}
                      fullWidth={true}
                      label={i18n.t('urlLink')}
                      helperText={i18n.t('thisFieldIsOptional')}
                      maxLength='255'
                      onChange={async (event) => {
                        this.state.alertEdited.moreInformationUrl = event.target.value;
                        await this.forceUpdateAsync();
                        await this._updateSmsInformationsEditing();
                      }}
                      value={this.state.alertEdited.moreInformationUrl}
                    />
                  </Row>
                </Col>
              </Row>
              {
                !this.state.alertEdited.sent &&
                <React.Fragment>
                  <Row className='editAlertRecurrentTitleContainer'>
                    <h5>{i18n.t('alertProgramming')}</h5>
                  </Row>
                  <Row>
                    <Col sm='6' className='editAlertDateTimeContainer'>
                      <h6>{i18n.t('dispatchDate')}</h6>
                      <DatePicker
                        selected={this.state.alertEdited.dateToSend.toDate()}
                        onChange={this._handleDateChangeEdited}
                        minDate={moment().toDate()}
                        popperPlacement={'top'}
                        popperModifiers={{
                          flip: {
                            behavior: ['top']
                          },
                          preventOverflow: {
                            enabled: false
                          },
                          hide: {
                            enabled: false
                          }
                        }}
                      />
                    </Col>
                    <Col sm='6' className='editAlertDateTimeContainer'>
                      <h6>{i18n.t('dispatchTime')}</h6>
                      <TimePicker
                        onChange={this._handleTimeChangeEdited}
                        value={this.state.alertEdited.timeToSend}
                        sx={{padding: '0px'}}

                      />
                    </Col>
                  </Row>
                  {this.state.alertEdited.pattern &&
                    <React.Fragment>
                      <Row className='editAlertRecurrentTitleContainer'>
                        <h5>{i18n.t('recurrence')}</h5>
                      </Row>
                      <Row>
                        <Col sm='2'>
                          <TextField
                            variant='standard'
                            type='number'
                            inputProps={{ min: 1 }}
                            name='patternCountEdited'
                            placeholder={i18n.t('periodicity')}
                            fullWidth={true}
                            label={i18n.t('periodicity')}
                            helperText={i18n.t('thisFieldIsRequired')}
                            onChange={async (event) => {
                              this.state.alertEdited.patternCount = event.target.value;
                              await this.forceUpdateAsync();
                            }}
                            value={this.state.alertEdited.patternCount}
                            error={this.state.alertEdited.patternCount < 1}
                          />
                        </Col>
                        <Col sm='6'>
                          <FormControl variant='standard' fullWidth={true}>
                            <InputLabel>{i18n.t('repeatability')}</InputLabel>
                            <Select
                              variant='standard'
                              value={this.state.alertEdited.pattern}
                              onChange={this._handleChangePatternEdit}
                            >
                              {
                                Object.keys(DelayPatterns).map((pattern) =>
                                  <MenuItem key={pattern} value={pattern}>{DelayPatterns[pattern].name}</MenuItem>
                                )
                              }
                            </Select>
                          </FormControl>
                        </Col>
                        <Col sm='4'>
                          <h6>{i18n.t('endDateOfRecurrence')}</h6>
                          <DatePicker
                            selected={this.state.alertEdited.endDate.toDate()}
                            onChange={this._handleEndDateChangeEdited}
                            minDate={moment().toDate()}
                            popperPlacement={'top'}
                            popperModifiers={{
                              flip: {
                                behavior: ['bottom']
                              },
                              preventOverflow: {
                                enabled: false
                              },
                              hide: {
                                enabled: false
                              }
                            }}
                          />
                        </Col>
                      </Row>
                    </React.Fragment>
                  }
                  {(this.state.alertEdited.smsSupported || this.state.alertEdited.voiceSupported || this.state.alertEdited.facebookSupported) && !this.state.alertEdited.sent &&
                    <React.Fragment>
                      <Row className='communicationChannelsTitleContainer'>
                        <h5>{i18n.t('communicationChannels')}</h5>
                      </Row>
                      <Row>
                        <Col sm='6' className='categoryCheckbox'>
                          <h6>{i18n.t('sendTheAlertViaCarecityPushNotification')}</h6>
                        </Col>
                        <Col sm='1' >
                          <Checkbox
                            checked={this.state.alertEdited.push}
                            color='default'
                            onChange={async (_, value) => {
                              this.state.alertEdited.push = value;
                              await this.forceUpdateAsync();
                            }}
                          />
                        </Col>
                      </Row>
                      {this.state.alertEdited.facebookSupported &&
                        <Row>
                          <Col sm='6' className='categoryCheckbox'>
                            <h6>{i18n.t('sendTheAlertOnAssociatedFacebookPage')}</h6>
                          </Col>
                          <Col sm='1' >
                            <Checkbox
                              checked={this.state.alertEdited.facebook}
                              color='default'
                              onChange={async (_, value) => {
                                this.state.alertEdited.facebook = value;
                                await this.forceUpdateAsync();
                              }}
                            />
                          </Col>
                        </Row>
                      }
                      {this.state.alertEdited.smsSupported &&
                        <Row>
                          <Col sm='6' className='categoryCheckbox'>
                            <h6>{i18n.t('sendTheAlertViaSms')}</h6>
                          </Col>
                          <Col sm='1' >
                            <Checkbox
                              checked={this.state.alertEdited.sms}
                              color='default'
                              onChange={async (_, value) => {
                                this.state.alertEdited.sms = value;
                                await this.forceUpdateAsync();
                              }}
                            />
                          </Col>
                        </Row>
                      }
                      {this.state.alertEdited.sms && <p className='smsCounter'>{i18n.t('numberOfSmsCharacters', { smsLength: this.state.alertEdited.smsLengthEdited })}</p>}
                      {this.state.alertEdited.sms && <p className='smsCounter'>{i18n.t('numberOfSmsSegments', { smsSegments: this.state.alertEdited.smsSegmentsEdited })}</p>}
                      {this.state.alertEdited.voiceSupported &&
                        <Row>
                          <Col sm='6' className='categoryCheckbox'>
                            <h6>{i18n.t('sendTheAlertViaVoiceCall')}</h6>
                          </Col>
                          <Col sm='1' >
                            <Checkbox
                              checked={this.state.alertEdited.voice}
                              color='default'
                              onChange={async (_, value) => {
                                this.state.alertEdited.voice = value;
                                await this.forceUpdateAsync();
                              }}
                            />
                          </Col>
                        </Row>
                      }
                    </React.Fragment>
                  }
                </React.Fragment>
              }
            </form>
          </DialogContent>
          <DialogActions className='editAlertButtonsContainer'>
            <Button
              variant='outlined'
              onClick={async () => await this._cancelEditing(alert)}
              className='editAlertCancelButton'
            >
              {i18n.t('cancel')}
            </Button>
            <Button
              variant='outlined'
              onClick={async () => await this._validateAlertModification()}
              className={!this.state.alertEdited.pattern || this.state.alertEdited.patternCount > 0 ? 'editAlertConfirmButton' : 'editAlertConfirmButtonDisabled'}
              disabled={this.state.alertEdited.pattern && this.state.alertEdited.patternCount < 1}

            >
              {i18n.t('edit')}
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  }

  _renderAlertsDatatables() {
    const lowerCaseQuery = deburr(this.state.userQuery.toLowerCase());

    return (
      <React.Fragment>
        <Row className='searchAlertsTableContainer'>
          <Col sm='6'>
            <TextField
              variant='standard'
              placeholder={i18n.t('enterTheAlertTitle')}
              fullWidth={true}
              label={i18n.t('searchForAnAlert')}
              maxLength='255'
              onChange={this._onUserQueryChange}
              value={this.state.userQuery}
            />
            <br />
          </Col>
          {
            this.state.userCities.length > 1 &&
            <Col sm='6'>
              <FormControl variant='standard' fullWidth={true}>
                <InputLabel>{i18n.t('carecityCities')}</InputLabel>
                <Select
                  variant='standard'
                  value={this.state.cityToFetch}
                  onChange={async (_event) => await this._filterAlerts(_event.target.value)}
                >
                  <MenuItem key={0} value={0}>{i18n.t('allMyCities')}</MenuItem>;
                  {
                    this.state.userCities.map(function (city) {
                      return <MenuItem key={city.cityId} value={city.cityId}>{city.name}</MenuItem>;
                    })
                  }
                </Select>
              </FormControl>
            </Col>
          }
        </Row>
        <Table
          sortDirection={this.state.sortDirection}
          columnToSort={this.state.columnToSort}
          handleConsult={this._toggleConsult}
          handleStatus={this._toggleDeliveryStatus}
          handleEdit={this._toggleEditing}
          handleDuplicate={this._handleDuplicate}
          handleRemove={this._handleRemove}
          handleSort={this._handleSort}
          isEditSupported={this._isEditSupported}
          isStatusSupported={this._isStatusSupported}
          editNotSupportedReason={this._editNotSupportedReason}
          data={orderBy(
            lowerCaseQuery
              ? this.state.alerts.filter(x =>
                deburr(x['title'])
                  .toLowerCase()
                  .includes(lowerCaseQuery)
              )
              : this.state.alerts,
            this.state.columnToSort,
            this.state.sortDirection)}
          header={[
            {
              name: i18n.t('dispatchDate'),
              prop: 'dateToSendFormatted'
            },
            {
              name: i18n.t('title'),
              prop: 'title'
            },
            {
              name: i18n.t('communicationChannels'),
              prop: 'communicationChannels'
            }
          ]}
        />
      </React.Fragment>
    );
  }

  _renderCitiesSelect() {
    if (this.state.groupsSelected.length === 0 && this.state.userCities.length > 1) {
      return (
        <React.Fragment>
          <Row>
            <Col sm='12'>
              <Multiselect
                label={i18n.t('targetedCities')}
                isMulti={true}
                onChange={this._handleSelectMultiCitiesChange}
                options={this.state.userCities.map((city) => {
                  return { label: city.name, value: city.cityId, smsEnabled: city.smsEnabled, voiceEnabled: city.voiceEnabled, facebookEnabled: city.facebookEnabled };
                })}
                placeholder={i18n.t('targetedCities')}
                value={this.state.userCitiesSelected}
                className='multiSelectCity'
              />
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  }

  _renderGroupsSelect() {
    if ((this.state.userCitiesSelected.length === 0 || this.state.userCities.length === 1) && this.state.groups && this.state.groups.length > 0) {
      return (
        <React.Fragment>
          <Row>
            <Col sm='12'>
              <Multiselect
                label={i18n.t('targetedGroups')}
                isMulti={true}
                onChange={this._handleGroupsSelect}
                options={this.state.groups.map(group => {
                  return { label: group.name, value: group.id };
                })}
                placeholder={i18n.t('targetedGroups')}
                value={this.state.groupsSelected}
                className='multiSelectGroup'
              />
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  }

  _renderAlertForm() {
    const languageEnabled = i18n.getLanguageEnabled();
    return (
      <Form>
        <Row>
          <Col sm='8'>
            <TextField
              variant='standard'
              name='title'
              placeholder={i18n.t('addTitle')}
              fullWidth={true}
              label={i18n.t('title')}
              helperText={i18n.t('thisFieldIsRequired')}
              maxLength='255'
              multiline={true}
              onChange={this._handleTitleContentUrlChange}
              value={this.state.title}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
            />
            <br />
          </Col>
          <Col sm='4'>
            <FormControl variant='standard' fullWidth={true}>
              <InputLabel>{'Type'}</InputLabel>
              <Select
                variant='standard'
                value={this.state.type}
                onChange={this._handleChangeType}
              >
                {
                  Object.keys(this.state.alertTypes).sort((a, b) => {
                    return this.state.alertTypes[a][languageEnabled] > this.state.alertTypes[b][languageEnabled] ? 1
                      : (this.state.alertTypes[a][languageEnabled] < this.state.alertTypes[b][languageEnabled] ? -1 : 0);
                  }).map((type) => {
                    if (type !== 'night_parking') {
                      return <MenuItem key={type} value={type}>{this.state.alertTypes[type][languageEnabled]}</MenuItem>;
                    }
                    return null;
                  })
                }
              </Select>
            </FormControl>
          </Col>
          <br />
        </Row>
        <TextField
          variant='standard'
          name='content'
          id='f_message'
          placeholder={i18n.t('addContent')}
          fullWidth={true}
          label={i18n.t('content')}
          helperText={i18n.t('thisFieldIsRequired')}
          multiline={true}
          onChange={this._handleTitleContentUrlChange}
          value={this.state.content}
        />
        <TextField
          variant='standard'
          name='moreInformationUrl'
          placeholder={i18n.t('addUrlLink')}
          fullWidth={true}
          label={i18n.t('urlLink')}
          helperText={i18n.t('thisFieldIsOptional')}
          maxLength='255'
          onChange={this._handleTitleContentUrlChange}
          value={this.state.moreInformationUrl}
        />
        <br />
        {this._renderCitiesSelect()}
        {this._renderGroupsSelect()}
        <br />
        {this._renderCommunicationsChannelsCheckbox()}
        {this._renderImagePreview()}
        {
          this.state.pictureBase64 && <IconButton
            aria-label='Delete'
            onClick={async () => {
              this.alertImageInput.value = '';
              await this.setStateAsync({ pictureBase64: '' });
            }}
            size='large'>
            <DeleteIcon />
          </IconButton>
        }
        <Row>
          <Col xs={12} sm={12} md={12} lg={3}>
            <input
              accept={SupportedImageFileTypesString}
              className='imageInput'
              id='addImage'
              type='file'
              onChange={this._onImageFileChange}
              ref={alertImageInput => this.alertImageInput = alertImageInput}
            />
            <label className='addImageLabel' htmlFor='addImage'>
              <Button
                htmlFor='addImage'
                variant='outlined'
                component='span'
                fullWidth={true}
                className='secondaryButton'
              >
                {this.state.pictureBase64 ? i18n.t('editTheImage') : i18n.t('addAnImage')}
              </Button>
            </label>
          </Col>
          <Col xs={12} sm={12} md={12} lg={3}>
            <Button
              variant='outlined'
              fullWidth={true}
              onClick={async () => await this.setStateAsync({ openAlertScheduling: true })}
              className='secondaryButton'
            >
              {i18n.t('scheduleTheAlert')}
            </Button>
          </Col>
          {
            this.state.groupsSelected.length === 0 &&
            <Col xs={12} sm={12} md={12} lg={3}>
              <Button
                variant='outlined'
                fullWidth={true}
                onClick={async () => await this._openAlertTargeting()}
                className='secondaryButton'
              >
                {i18n.t('targetAlertTitle')}
              </Button>
            </Col>
          }
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={6}>
            <Button
              variant='contained'
              onClick={this._validateAlert}
              className='sendAlertButton'
              fullWidth={true}
            >
              {i18n.t('send')}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const accessLevel = User.getInstance().getUser().access_level;
    if (accessLevel === 'publisher_communications' || accessLevel === 'admin' || accessLevel === 'super_admin') {
      return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='en'>
          <Container className='Alert'>
            {this.state.loading && <LoadingPage />}
            <Row>
              <h2 className={'pageTitle'}>{i18n.t('sendAnAlert')}</h2>
            </Row>
            {this._renderAlertForm()}
            <Row>
              <h2 className={'pageTitle'}>{i18n.t('previousAlerts')}</h2>
            </Row>
            {this._renderAlertsDatatables()}
            {this._renderDialogs()}
            {this._renderAlertModicationForm()}
            {this._renderAlertDetails()}
            {this._renderAlertDeliveryStatus()}
            {this._renderAlertScheduling()}
            {this._renderAlertTargeting()}
            <ToastContainer />
          </Container>
        </LocalizationProvider>
      );
    }
    else {
      return (
        <AccessForbidden />
      );
    }
  }
}

export default Alert;
