import React from 'react';
import AsyncComponent from '../AsyncComponent';
import { Container, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import faThumbsUp from '@fortawesome/fontawesome-free-solid/faThumbsUp';
import faThumbsDown from '@fortawesome/fontawesome-free-solid/faThumbsDown';
import ImageLoader from 'react-load-image';
import { List } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

import { ProblemCategories, ProblemStatus } from '../../utils/Enums';
import Problems from '../../data/Problems';
import { NoImageCardSrc } from '../../utils/ImageHelper';
import './styles.scss';
import i18n from '../../i18n';
const spinner = require('../../images/loading_spinner.gif');

function Preloader() {
  return <img
    src={spinner}
    alt={i18n.t('loading') + '...'}
  />;
}

class Problem extends AsyncComponent {
  constructor(props) {
    super(props);

    this.problemsData = Problems.getInstance();

    this._handleMouseOver = this._handleMouseOver.bind(this);
  }

  _handleMouseOver() {
    if (!this.problemsData.getCurrentProblem() || this.problemsData.getCurrentProblem().id !== this.props.id) {
      this.problemsData.setCurrentProblem(this.props);
    }
  }

  render() {
    return (
      <List.Item className='Problem' onMouseOver={this._handleMouseOver}>
        <Link underline='hover' to={'problem/' + this.props.id}>
          <Container style={{ height: '175px' }}>
            <Row className='rowProblem'>
              <Col md='4' sm='3' xs='3' className='itemImage'>
                <ImageLoader
                  src={this.props.picture_url === '' ? NoImageCardSrc : this.props.picture_url}>
                  <img />
                  <div src={NoImageCardSrc}></div>
                  <Preloader />
                </ImageLoader>
              </Col>
              <Col md='8' sm='9' xs='9' className='itemContent'>
                <Row className='itemTitle'>
                  <Col md='12'>
                    <p className='problemNumber'>{i18n.t('report')} #{this.props.id}</p>
                  </Col>
                </Row>
                <Row className='itemTitle'>
                  <Col md='12'>
                    <p className='problemCategory'>{ProblemCategories[this.props.category].name}
                      <span className='date'>{' - ' + moment(this.props.created_at).format('YYYY-MM-DD')}</span>
                    </p>
                  </Col>
                </Row>
                <Row className='itemTitle'>
                  <Col md='12'>
                    <p className='problemStatus'>{i18n.t('status')}: <span className='date'>{ProblemStatus[this.props.status].name}</span></p>
                  </Col>
                </Row>
                {this.props.reminderLevels &&
                  <Row className='itemTitle'>
                    <Col md='12'>
                      {this.props.reminderLevels['high'] <= this.props.daysSinceLastUpdate &&
                        <p className='updatedAtRed daysSinceLastUpdate'>{i18n.t('lastEditedXDaysAgo', { days: this.props.daysSinceLastUpdate })}</p>
                      }
                      {this.props.reminderLevels['moderate'] <= this.props.daysSinceLastUpdate && this.props.reminderLevels['high'] > this.props.daysSinceLastUpdate &&
                        <p className='updatedAtOrange daysSinceLastUpdate'>{i18n.t('lastEditedXDaysAgo', { days: this.props.daysSinceLastUpdate })}</p>
                      }
                      {this.props.reminderLevels['low'] <= this.props.daysSinceLastUpdate && this.props.reminderLevels['moderate'] > this.props.daysSinceLastUpdate &&
                        <p className='updatedAtGreen daysSinceLastUpdate'>{i18n.t('lastEditedXDaysAgo', { days: this.props.daysSinceLastUpdate })}</p>
                      }
                    </Col>
                  </Row>
                }
                <Row className='itemDescription'>
                  <Col md='12'>
                    <p>{this.props.description}</p>
                  </Col>
                </Row>
                <Row className='itemOtherInfo'>
                  <Col md='12' style={{ paddingRight: '0' }}>
                    <Container style={{ paddingRight: '0' }}>
                      <Row style={{ height: '100%', paddingTop: '6px', justifyContent: 'flex-end', color: '#999' }}>
                        <Col md='12' xs='12' style={{ textAlign: 'end', overflow: 'hidden', paddingRight: '0' }}>
                          <Container>
                            <span style={{ paddingRight: '2px', paddingLeft: '4px' }}>{this.props.thumbs_up_count}</span>
                            <FontAwesomeIcon style={{ paddingBottom: '0px', marginRight: '0px' }} icon={faThumbsUp} />
                            <span className='vl2'></span>
                            <span style={{ paddingRight: '2px' }}>{this.props.thumbs_down_count}</span>
                            <FontAwesomeIcon style={{ paddingTop: '2px' }} icon={faThumbsDown} />
                          </Container>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Link>
      </List.Item>
    );
  }
}

export default Problem;
