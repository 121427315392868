import React from 'react';
import AsyncComponent from '../AsyncComponent';
import ImageLoader from 'react-load-image';
import { Link } from 'react-router-dom';
import { OverlayView } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';

import { ProblemCategories, ProblemStatus } from '../../utils/Enums';
import { NoImageCardSrc } from '../../utils/ImageHelper';
import './styles.scss';
import i18n from '../../i18n';
const spinner = require('../../images/loading_spinner.gif');

function Preloader() {
  return <img
    src={spinner}
    alt={i18n.t('loading') + '...'}
  />;
}

class MarkerOverlay extends AsyncComponent {
  constructor(props) {
    super(props);
    this._handleClick = this._handleClick.bind(this);
  }

  _handleClick() {
    this.props.navigate('problem/' + this.props.problem.id);
  }

  render() {
    return (
      <OverlayView
        key={'markerLabel'}
        position={{ lat: this.props.problem.latitude, lng: this.props.problem.longitude }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <div className='markerLabel'>
          <Link underline='hover' to={'problem/' + this.props.problem.id}>
            <div className='labelRow'>
              <div className='itemDescr'>
                <p className='id'>{i18n.t('report')} #<span>{this.props.problem.id} </span></p>
                <p className='category'>{i18n.t('category')}: {ProblemCategories[this.props.problem.category].name}</p>
                <p className='status'>{i18n.t('status')}: {ProblemStatus[this.props.problem.status].name}</p>
              </div>
              <div className='itemImage'>
                <ImageLoader
                  src={this.props.problem.picture_url === '' ? NoImageCardSrc : this.props.problem.picture_url}
                >
                  <img />
                  <div src={NoImageCardSrc}></div>
                  <Preloader />
                </ImageLoader>
              </div>
            </div>
          </Link>
        </div>
      </OverlayView>
    );
  }
}

function MarkerOverlayComponent(props) {
  const navigate = useNavigate();

  return (
    <MarkerOverlay navigate={navigate} {...props} />
  );
}

export default MarkerOverlayComponent;
