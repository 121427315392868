import React from 'react';
import AsyncComponent from '../../components/AsyncComponent';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import { Row } from 'reactstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import './styles.scss';
import { useNavigate } from 'react-router-dom';

import UsersService from '../../services/UsersService';
import User from '../../data/User';
import Cities from '../../data/Cities';
import i18n from '../../i18n';

import Footer from '../../Layout/Footer';

import CarecityLogo from '../../images/logo_carecityapp_blue.png';
import Spinner from '../../images/loading_spinner.gif';

class Login extends AsyncComponent {
  constructor(props) {
    super(props);

    this.userData = User.getInstance();
    this.userData.logout();
    this.citiesData = Cities.getInstance();

    this.state = {
      username: '',
      password: '',
      forgotPassword: false,
      loginFail: false,
      loggingIn: false,
      submitted: false,
    };

    this._login = this._login.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this._handleForgotPassword = this._handleForgotPassword.bind(this);
    this._handleSubmitPassword = this._handleSubmitPassword.bind(this);
    this._handleClose = this._handleClose.bind(this);
  }

  async _handleChange(e) {
    const { name, value } = e.target;
    await this.setStateAsync({ [name]: value });
  }

  async _handleClose() {
    await this.setStateAsync({ forgotPassword: false, submitted: false });
  }

  async _handleForgotPassword() {
    await this.setStateAsync({ forgotPassword: true, submitted: false });
  }

  async _handleSubmit() {
    await this.setStateAsync({ submitted: true, loginFail: false });

    if (this.state.username && this.state.password) {
      await this.setStateAsync({ loggingIn: true });
      await this._login(this.state.username, this.state.password);
    }
  }

  async _handleSubmitPassword(e) {
    e.preventDefault();
    await this.setStateAsync({ forgotPassword: false, submitted: true });

    if (this.state.username) {
      await UsersService.requestPassword(this.state.username);
    }
  }

  async _login(username, password) {
    const user = await this.userData.fetchUser(username, password);
    if (user) {
      await this.setStateAsync({ loggingIn: false });

      if (this.props.location && this.props.location.state !== undefined && this.props.location.state.pathname !== '/') {
        this.props.navigate(this.props.location.state.from.pathname);
      }
      else {
        if (user.access_level === 'publisher_citizens') {
          this.props.navigate('/Subscribers');
        }
        else {
          user.access_level === 'publisher_communications' ? this.props.navigate('/Alerts') : this.props.navigate('/');
        }
      }
    }
    else {
      await this.setStateAsync({ loginFail: true, loggingIn: false });
    }
  }

  _renderForgotPasswordDialog() {
    return (
      <Dialog
        open={this.state.forgotPassword}
        onClose={this._handleClose}
      >
        <DialogTitle>{i18n.t('passwordReset')}</DialogTitle>
        <DialogContent>
          <TextField
            variant='standard'
            className='field'
            name='username'
            placeholder={i18n.t('enterYourEmail')}
            fullWidth={true}
            label={i18n.t('email')}
            inputProps={{ maxLength: 255 }}
            onChange={this._handleChange}
            value={this.state.username}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this._handleClose} color='primary'>
            {i18n.t('cancel')}
          </Button>
          <Button onClick={this._handleSubmitPassword} color='primary'>
            {i18n.t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Grid container className='Login'>
          <CssBaseline />
          <Grid item xs={12} sm={12} md={5} className='formContainer'>
            <div className='paper'>
              <form className='loginForm'>
                <Row className='logoImageContainer'>
                  <img alt='Carecity' className='logoImage' src={CarecityLogo} />
                </Row>
                <div className='fieldContainer'>
                  <TextField
                    variant='standard'
                    className='field'
                    name='username'
                    error={this.state.submitted && this.state.username === ''}
                    helperText={this.state.submitted && this.state.username === '' ? i18n.t('enterYourEmail') : ' '}
                    placeholder={i18n.t('enterYourEmail')}
                    fullWidth={true}
                    label={i18n.t('email')}
                    inputProps={{ maxLength: 255 }}
                    onChange={this._handleChange}
                    value={this.state.username}
                  />
                  <TextField
                    variant='standard'
                    className='field'
                    type='password'
                    name='password'
                    error={this.state.submitted && this.state.password === ''}
                    helperText={this.state.submitted && this.state.password === '' ? i18n.t('enterYourPassword') : ' '}
                    placeholder={i18n.t('enterYourPassword')}
                    fullWidth={true}
                    label={i18n.t('password')}
                    onChange={this._handleChange}
                    onKeyUp={(event) => {
                      if (event.key === 'Enter') {
                        this._handleSubmit();
                      }
                    }}
                    value={this.state.password}
                  />
                  <Row className='forgotPasswordContainer'>
                    <Button
                      fullWidth
                      onClick={this._handleSubmit}
                      className='connectButton'>
                      {i18n.t('login')}
                    </Button>
                  </Row>
                  <Row className='forgotPasswordContainer'>
                    <Button
                      onClick={this._handleForgotPassword}
                      className='forgotPassword'>
                      {i18n.t('forgotPassword')}
                    </Button>
                  </Row>
                  {this.state.loginFail &&
                    <Row>
                      <p className='errorMessage'>{i18n.t('incorrectEmailOrPasswordWarning')}</p>
                    </Row>
                  }
                  <Row className='forgotPasswordContainer'>
                    {this.state.loggingIn &&
                      <img src={Spinner} alt={i18n.t('loading')} />
                    }
                  </Row>
                  {this._renderForgotPasswordDialog()}
                </div>
              </form>
            </div>
          </Grid>
          <Grid item xs={false} sm={false} md={7} className='image' />
        </Grid>
        <Footer />
      </React.Fragment>
    );
  }
}

function LoginScene(props) {
  const navigate = useNavigate();

  return (
    <Login navigate={navigate} {...props} />
  );
}

export default LoginScene;