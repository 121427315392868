import React from 'react';

import ScaleLoader from 'react-spinners/ScaleLoader';

import './styles.scss';

const LoadingPage = () => (
  <div className='overlay'>
    <ScaleLoader color='#223A5E' size={150} loading={true} height={'125px'} width={'25px'} radius={'5px'} margin={'5px'} />
  </div>
);

export default LoadingPage;