import React from 'react';
import AsyncComponent from '../../components/AsyncComponent';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { PatternFormat } from 'react-number-format';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { ToastContainer, toast } from 'react-toastify';
import Typography from '@mui/material/Typography';

import SubscribersService from '../../services/SubscribersService';
import User from '../../data/User';

import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';
import i18n from '../../i18n';

class SubscriptionForm extends AsyncComponent {
  constructor(props) {
    super(props);
    this.state = {
      cities: [],
      citizenCity: '',
      confirmationDialog: false,
      confirmationNumber: '',
      dialogMessage: '',
      dialogTitle: '',
      latlng: null,
      name: '',
      openDialog: false,
      phoneNumber: '',
      smsEnabled: true,
      voiceEnabled: false
    };

    this._handleClose = this._handleClose.bind(this);
    this._handleInputChange = this._handleInputChange.bind(this);
    this._sendSubscription = this._sendSubscription.bind(this);
  }

  async componentDidMount() {
    await this._fetchCities();
  }

  async _fetchCities() {
    let cities = await User.getInstance().getUserCities();
    cities = cities.filter(city => city.smsEnabled || city.voiceEnabled);

    await this.setStateAsync({ cities, citizenCity: cities[0].cityId });
  }

  async _handleClose(_event, reason) {
    if (reason && reason === 'backdropClick')
      return false;

    await this.setStateAsync({
      dialogTitle: '',
      dialogMessage: '',
      openDialog: false
    });
  }

  async _handleInputChange(event) {
    const { name, value } = event.target;
    await this.setStateAsync({
      [name]: value
    });
  }

  async _toggleSmsEnabled(smsEnabled) {
    await this.setStateAsync({
      smsEnabled,
      voiceEnabled: !smsEnabled
    });
  }

  async _toggleVoiceEnabled(voiceEnabled) {
    await this.setStateAsync({
      smsEnabled: !voiceEnabled,
      voiceEnabled
    });
  }

  async _sendSubscription() {
    if (this.state.name === '') {
      await this.setStateAsync({
        dialogTitle: i18n.t('errorDuringRegistration'),
        dialogMessage: i18n.t('pleaseFillInTheFullName')
      });
    }
    else if (this.state.phoneNumber.length === '') {
      await this.setStateAsync({
        dialogTitle: i18n.t('errorDuringRegistration'),
        dialogMessage: i18n.t('pleaseFillInThePhoneNumber')
      });
    }
    else if (this.state.citizenCity === '') {
      await this.setStateAsync({
        dialogTitle: i18n.t('errorDuringRegistration'),
        dialogMessage: i18n.t('pleaseFillInTheCity')
      });
    }
    else if (this.state.voiceEnabled === false && this.state.smsEnabled === false) {
      await this.setStateAsync({
        dialogTitle: i18n.t('errorDuringRegistration'),
        dialogMessage: i18n.t('pleaseFillInTheMeansOfReceivingAlerts.')
      });
    }
    else {
      const subscription = {
        name: this.state.name,
        phone_number: this.state.phoneNumber,
        city_id: this.state.citizenCity,
        subscriber_type: this.state.smsEnabled ? 'sms' : 'voice',
        latlng: this.state.latlng,
      };

      const success = await SubscribersService.subscribe(subscription);
      if (success) {
        toast.success(i18n.t('registrationSuccessfullyCompleted'));
        await this.setStateAsync({ name: '', phoneNumber: '', latlng: null });
        await this.props.fetchSubscribers(this.state.citizenCity);
      }
      else {
        toast.error(i18n.t('errorDuringRegistrationConfirmation'));
      }
    }
    await this.setStateAsync({ openDialog: false });
  }

  _convertInputToCustomTextMask = React.forwardRef((props, _ref) =>
    <PatternFormat
      {...props}
      format='+1 (###) ###-####'
      mask='_'
      placeholder='+1 (123) 456-7891'
    />
  );

  _renderServicesForm() {
    if (this.state.citizenCity !== '') {
      const city = this.state.cities.find(city => city.cityId === this.state.citizenCity);

      return (
        <div>
          <Typography className='title' component='h6' variant='h6'>
            {i18n.t('byWhatMeansOfCommunicationDoYouWishToBeReached')}
          </Typography>
          <div className='serviceChoicesContainer'>
            {
              city.smsEnabled &&
              <FormControlLabel
                control={<Checkbox
                  checked={this.state.smsEnabled}
                  color='default'
                  onChange={async (_, value) => await this._toggleSmsEnabled(value)}
                />}
                label={i18n.t('smsAlerts')}
              />
            }
            {
              city.voiceEnabled &&
              <FormControlLabel
                control={<Checkbox
                  checked={this.state.voiceEnabled}
                  color='default'
                  onChange={async (_, value) => await this._toggleVoiceEnabled(value)}
                />}
                label={i18n.t('automatedCalls')}
              />
            }
          </div>
        </div>
      );
    }
  }

  _renderDialog() {
    return (
      <React.Fragment>
        <Dialog
          open={this.state.openDialog}
          onClose={this._handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{this.state.dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {this.state.dialogMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this._handleClose} color='primary'>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

  render() {
    return (
      <Grid container className='SubscriptionForm'>
        <ToastContainer />
        <Grid item xs={12} sm={12} md={12} elevation={15}>
          <div>
            <form noValidate>
              <h4 className='title'>{i18n.t('carecityAlertRegistrationForm')}</h4>
              <div className='fieldContainer'>
                <TextField
                  variant='standard'
                  className='field'
                  name='name'
                  placeholder={i18n.t('enterTheFullName')}
                  fullWidth={true}
                  label={i18n.t('fullName')}
                  inputProps={{ maxLength: 255 }}
                  onChange={this._handleInputChange}
                  value={this.state.name}
                />
                <FormControl variant='standard' fullWidth={true} className='field'>
                  <InputLabel>{i18n.t('phoneNumber')}</InputLabel>
                  <Input
                    required
                    variant='outlined'
                    name='phoneNumber'
                    value={this.state.phoneNumber}
                    onChange={this._handleInputChange}
                    id='formatted-text-mask-input'
                    inputComponent={this._convertInputToCustomTextMask}
                  />
                </FormControl>
                {this.state.cities.length > 1 &&
                  <FormControl variant='standard' fullWidth={true} className='field'>
                    <InputLabel>{i18n.t('chooseACarecityCity')}</InputLabel>
                    <Select
                      variant='standard'
                      value={this.state.citizenCity}
                      onChange={async (_event) => await this.setStateAsync({ citizenCity: _event.target.value })}
                    >
                      {
                        this.state.cities.map(function (city) {
                          return <MenuItem key={city.cityId} value={city.cityId}>{city.name}</MenuItem>;
                        })
                      }
                    </Select>
                  </FormControl>
                }
                <div className='googleAutocomplete'>
                  <GooglePlacesAutocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_KEY}
                    selectProps={{
                      placeholder: i18n.t('enterYourAddress'),
                      onChange: async result => {
                        const response = await geocodeByAddress(result.label);
                        const latlng = await getLatLng(response[0]);
                        await this.setStateAsync({ latlng });
                      },
                    }}
                  />
                </div>
              </div>
              {this._renderServicesForm()}
              <Grid container item spacing={0}>
                <Grid item xs={12} sm={12} md={6}>
                  <Button
                    fullWidth
                    className='submitButton'
                    onClick={this._sendSubscription}
                  >
                    {i18n.t('validateRegistration')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Grid>
        {this._renderDialog()}
      </Grid>
    );
  }
}

export default SubscriptionForm;
