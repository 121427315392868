import React from 'react';
import { Navigate } from 'react-router-dom';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import './styles.scss';

export const PrivateRoute = ({ component: Component, ...props }) => (
  localStorage.getItem('user')
    ?
    <React.Fragment>
      <Header />
      <Component {...props} />
      <div className='Filler'></div>
      <Footer />
    </React.Fragment>
    : <Navigate to={{ pathname: '/login', state: { from: props.location } }} />
);
