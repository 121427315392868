import React from 'react';
import AsyncComponent from '../../components/AsyncComponent';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import { Container, Col, Form, Row } from 'reactstrap';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import orderBy from 'lodash/orderBy';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { ToastContainer, toast } from 'react-toastify';

import AccessForbidden from '../../components/AccessForbidden';
import CitiesService from '../../services/CitiesService';
import ParkingService from '../../services/ParkingService';
import CitiesData from '../../data/Cities';
import Table from '../../components/Table';
import User from '../../data/User';
import { SupportedImageFileTypes, SupportedImageFileTypesString, NoImageCitySrc } from '../../utils/ImageHelper';
import './styles.scss';
import i18n from '../../i18n';

const invertDirection = {
  asc: 'desc',
  desc: 'asc'
};

class Cities extends AsyncComponent {
  constructor(props) {
    super(props);
    this.state = {
      anchorEls: {},
      cities: [],
      cityState: '',
      cityCountry: '',
      cityName: '',
      cityToDelete: {},
      columnToSort: 'count',
      editing: false,
      geographicCities: [],
      geographicCityToDelete: {},
      imageFileEdited: null,
      imagePreviewUrlEdited: '',
      nightParkingsCitiesList: [],
      openCity: false,
      openGeographicCity: false,
      openCityError: false,
      openOtherCity: false,
      otherCityQuery: '',
      otherCities: [],
      otherCityToDelete: {},
      sortDirection: 'desc',
    };

    this._addCity = this._addCity.bind(this);
    this._deleteCity = this._deleteCity.bind(this);
    this._deleteOtherCity = this._deleteOtherCity.bind(this);
    this._deleteGeographicCity = this._deleteGeographicCity.bind(this);
    this._handleClose = this._handleClose.bind(this);
    this._handleInputChange = this._handleInputChange.bind(this);
    this._handleRemoveOtherCity = this._handleRemoveOtherCity.bind(this);
    this._handleSort = this._handleSort.bind(this);
    this._onOtherCityQueryChange = this._onOtherCityQueryChange.bind(this);
  }

  async componentDidMount() {
    const cities = await CitiesData.getInstance().getCities();
    const geographicCities = await CitiesData.getInstance().getGeographicCities();
    const nightParkingsCitiesList = await ParkingService.fetchNightParkings();
    const otherCities = await CitiesService.fetchOtherCities();

    await this.setStateAsync({ cities, geographicCities, nightParkingsCitiesList, otherCities });
  }

  async _activateNightParking(city) {
    let success = true;
    if (!this.state.nightParkingsCitiesList.includes(city.cityId)) {
      const newNightParking = {
        city_id: city.cityId
      };

      success = await ParkingService.activateNightParking(newNightParking);
      if (success) {
        this.state.nightParkingsCitiesList.push(city.cityId);
        await this.setStateAsync({ nightParkingsCitiesList: this.state.nightParkingsCitiesList });
      }
      else {
        this._notifyError(i18n.t('errorDuringActivation'));
      }
    }

    return success;
  }

  async _deactivateNightParking(city) {
    let success = true;
    if (this.state.nightParkingsCitiesList.includes(city.cityId)) {
      success = await ParkingService.deleteNightParking(city.cityId);
      if (success) {
        this.state.nightParkingsCitiesList = this.state.nightParkingsCitiesList.filter(id => id !== city.cityId);
        await this.setStateAsync({ nightParkingsCitiesList: this.state.nightParkingsCitiesList });
      }
      else {
        this._notifyError(i18n.t('errorDuringDeactivation'));
      }
    }

    return success;
  }

  async _addCity() {
    if (this.state.cityName !== '' && this.state.cityState !== '' && this.state.cityCountry !== '') {
      const city = {
        name: this.state.cityName,
        state: this.state.cityState,
        country: this.state.cityCountry
      };

      const success = await CitiesService.addCity(city);
      if (success) {
        const cities = await CitiesData.getInstance().fetchCities();
        await this.setStateAsync({
          cities: cities,
          cityName: '',
          cityState: '',
          cityCountry: '',
        });
        this._notifySuccess(i18n.t('successfullyCreated'));
      }
      else {
        this._notifyError(i18n.t('errorDuringCreation'));
      }
    }
    else {
      await this._handleOpenCityError();
    }
  }

  async _modifyCity(city) {
    const successNightParking = city.nightParkingActivated ? await this._activateNightParking(city) : await this._deactivateNightParking(city);

    if (this.state.imageFileEdited !== null) {
      city.picture = this.state.imageFileEdited !== '' ? true : false;
    }

    const success = await CitiesService.modifyCity(city, this.state.imageFileEdited);

    if (success) {
      delete city.picture;
      city.cityEditingEnabled = false;
      city.smsEnabled = city.smsEnabledEdited;
      city.voiceEnabled = city.voiceEnabledEdited;
      await this.setStateAsync({ cities: this.state.cities, editing: false, imageFileEdited: null });
    }

    if (success && successNightParking) {
      this._notifySuccess(i18n.t('successfullyModified'));
    }
    else if (!success) {
      this._notifyError(i18n.t('errorDuringModification'));
    }
  }

  async _deleteCity() {
    const success = await CitiesService.deleteCity(this.state.cityToDelete.cityId);
    if (success) {
      this.state.cities = await CitiesData.getInstance().fetchCities();
      await this.setStateAsync({ openCity: false, cities: this.state.cities, cityToDelete: {} });
      this._notifySuccess(i18n.t('successfullyRemoved'));
    }
    else {
      this._notifyError(i18n.t('errorDuringDeletion'));
    }
  }

  async _deleteOtherCity() {
    const success = await CitiesService.deleteOtherCity(this.state.otherCityToDelete.city);
    if (success) {
      this.state.otherCities = this.state.otherCities.filter(otherCity => otherCity.city !== this.state.otherCityToDelete.city);
      await this.setStateAsync({ openOtherCity: false, otherCities: this.state.otherCities, otherCityToDelete: {} });
      this._notifySuccess(i18n.t('successfullyRemoved'));
    }
    else {
      this._notifyError(i18n.t('errorDuringDeletion'));
    }
  }

  async _modifyGeographicCity(city, cityId) {
    city.cityId = cityId === '' ? null : cityId;
    const cityToModify = {
      city_id: cityId,
      id: city.id,
    };

    const success = await CitiesService.modifyGeographicCity(cityToModify);
    if (success) {
      await CitiesData.getInstance().fetchGeographicCities();
      await this.setStateAsync({ geographicCities: this.state.geographicCities });
      this._notifySuccess(i18n.t('successfullyAssociated'));
    }
    else {
      this._notifyError(i18n.t('errorDuringAssociation'));
    }
  }

  async _deleteGeographicCity() {
    const success = await CitiesService.deleteGeographicCity(this.state.geographicCityToDelete.id);
    if (success) {
      this.state.geographicCities = await CitiesData.getInstance().fetchGeographicCities();
      await this.setStateAsync({ openGeographicCity: false, geographicCities: this.state.geographicCities, geographicCityToDelete: {} });
      this._notifySuccess(i18n.t('successfullyRemoved'));
    }
    else {
      this._notifyError(i18n.t('errorDuringDeletion'));
    }
  }

  async _cancelEditing(city) {
    await this._setAnchorEl(city.cityId, null);
    city.cityEditingEnabled = false;
    await this.setStateAsync({
      editing: false,
      cities: this.state.cities,
    });
  }

  async _handleSort(columnName) {
    await this.setStateAsync(state => ({
      columnToSort: columnName,
      sortDirection:
        state.columnToSort === columnName
          ? invertDirection[state.sortDirection]
          : 'asc'
    }));
  }

  async _handleClose() {
    await this.setStateAsync({ openCity: false, openGeographicCity: false, openCityError: false, openOtherCity: false });
  }

  async _handleInputChange(event) {
    const { name, value } = event.target;
    await this.setStateAsync({
      [name]: value
    });
  }

  async _handleOpenCity(city) {
    await this.setStateAsync({ openCity: true, cityToDelete: city });
  }

  async _handleOpenCityError() {
    await this.setStateAsync({ openCityError: true });
  }

  async _handleOpenGeographicCity(city) {
    await this.setStateAsync({ openGeographicCity: true, geographicCityToDelete: city });
  }

  async _handleRemoveOtherCity(city) {
    await this.setStateAsync({ openOtherCity: true, otherCityToDelete: city });
  }

  _notifySuccess(message) {
    toast.success(message);
  }

  _notifyError(message) {
    toast.error(message);
  }

  async _onOtherCityQueryChange(e) {
    await this.setStateAsync({ otherCityQuery: e.target.value });
  }

  async _setAnchorEl(cityId, anchorEl) {
    const anchorEls = this.state.anchorEls;
    anchorEls[cityId] = anchorEl;
    await this.setStateAsync({ anchorEls });
  }

  async _toggleEditing(city) {
    await this._setAnchorEl(city.cityId, null);
    city.smsEnabledEdited = city.smsEnabled;
    city.voiceEnabledEdited = city.voiceEnabled;
    city.cityEditingEnabled = true;
    this.state.nightParkingsCitiesList.includes(city.cityId) ? city.nightParkingActivated = true : city.nightParkingActivated = false;
    await this.setStateAsync({
      editing: true,
      imagePreviewUrlEdited: city.pictureUrl !== null ? city.pictureUrl : '',
    });
  }

  async _toggleNightParking(nightParkingActivated, city) {
    city.nightParkingActivated = nightParkingActivated;
    await this.setStateAsync({
      cities: this.state.cities,
    });
  }

  async _toggleSmsEnabled(smsEnabled, city) {
    city.smsEnabledEdited = smsEnabled;
    await this.setStateAsync({
      cities: this.state.cities,
    });
  }

  async _toggleVoiceEnabled(voiceEnabled, city) {
    city.voiceEnabledEdited = voiceEnabled;
    await this.setStateAsync({
      cities: this.state.cities,
    });
  }

  async _removePicture() {
    this.cityEditedImageInput.value = '';
    await this.setStateAsync({ imagePreviewUrlEdited: '', imageFileEdited: '' });
  }

  _renderCardImage(image, editing) {
    if (editing) {
      return (
        <div>
          <img
            src={this.state.imagePreviewUrlEdited === '' ? NoImageCitySrc : this.state.imagePreviewUrlEdited}
            className='cityImage'
            alt='City'
          />
          <Row className='editCityImageButtonsContainer'>
            <label htmlFor='contained-button-file2' className='editCityModifyImageButtonLabel'>
              <Button variant='outlined' component='span'
                className='editCityModifyImageButton'
              >
                {this.state.imagePreviewUrlEdited ? i18n.t('editTheImage') : i18n.t('addAnImage')}
              </Button>
            </label>
            {(this.state.imagePreviewUrlEdited !== '') &&
              <div className='deletePictureButtonContainer'>
                <IconButton
                  aria-label='Delete'
                  onClick={async () => await this._removePicture()}
                  size='large'>
                  <DeleteIcon />
                </IconButton>
              </div>
            }
          </Row>
          <div className={(this.state.imagePreviewUrlEdited !== '') ? 'editPictureButtonContainerWithDelete' : 'editPictureButtonContainer'}>
            <input
              accept={SupportedImageFileTypesString}
              id='contained-button-file2'
              type='file'
              className='imageInput'
              ref={cityEditedImageInput => this.cityEditedImageInput = cityEditedImageInput}
              onChange={
                async (e) => {
                  e.preventDefault();
                  const files = e.target.files;
                  if (files.length !== 0) {
                    const reader = new FileReader();
                    reader.onloadend = async () => {
                      if (SupportedImageFileTypes.includes(files[0].type.replace('image/', ''))) {
                        await this.setStateAsync({ imagePreviewUrlEdited: reader.result, imageFileEdited: files[0] });
                      }
                      else {
                        this._notifyError(i18n.t('theSelectedFileIsNotInASupportedFormat'));
                      }
                    };
                    reader.readAsDataURL(files[0]);
                  }
                }
              }
            />
          </div>
        </div>
      );
    }
    else {
      return (
        <img
          src={image !== null ? image : NoImageCitySrc}
          className='cityImage'
          alt='City'
        />
      );
    }
  }

  _renderCitiesCard() {
    return (this.state.cities.map(city =>
      <Card className='card' key={city.cityId}>
        <Row>
          <Col sm='6'>
            <h4 className='cardTitle'>{city.name + ', ' + city.state + ', ' + city.country}</h4>
          </Col>
          <Col sm='6'>
            <IconButton
              className='actions'
              aria-label='More'
              aria-owns={this.state.open ? 'long-menu' : undefined}
              aria-haspopup='true'
              onClick={async (event) => await this._setAnchorEl(city.cityId, event.currentTarget)}
              size='large'>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id={city.cityId}
              anchorEl={this.state.anchorEls[city.cityId]}
              open={Boolean(this.state.anchorEls[city.cityId])}
              onClose={async () => await this._setAnchorEl(city.cityId, null)}
            >
              {!this.state.editing &&
                <MenuItem onClick={async () => await this._toggleEditing(city)}>{i18n.t('edit')}</MenuItem>
              }
              <MenuItem onClick={async () => await this._handleOpenCity(city)}>{i18n.t('remove')}</MenuItem>
            </Menu>
          </Col>
        </Row>
        <Row className='cardHeader'>
          <Col xs={4} sm={4} md={3} lg={3}>
            {this._renderCardImage(city.pictureUrl, city.cityEditingEnabled)}
          </Col>
          <Col xs={8} sm={8} md={9} lg={9}>
            <Row className='cardHeader'>
              <h5>{i18n.t('services')}</h5>
            </Row>
            {!city.cityEditingEnabled &&
              <React.Fragment>
                <Row className='cardHeader'>
                  <h5 className='categoryCheckbox'>{i18n.t('nightParking')}</h5>
                  <Checkbox
                    className='servicesCheckbox'
                    disabled={true}
                    checked={this.state.nightParkingsCitiesList.includes(city.cityId)}
                    color='default'
                  />
                </Row>
                <Row className='cardHeader'>
                  <h5 className='categoryCheckbox'>{i18n.t('smsAlerts')}</h5>
                  <Checkbox
                    className='servicesCheckbox'
                    disabled={true}
                    checked={city.smsEnabled}
                    color='default'
                  />
                </Row>
                <Row className='cardHeader'>
                  <h5 className='categoryCheckbox'>{i18n.t('voiceAlerts')}</h5>
                  <Checkbox
                    className='servicesCheckbox'
                    disabled={true}
                    checked={city.voiceEnabled}
                    color='default'
                  />
                </Row>
              </React.Fragment>
            }
            {city.cityEditingEnabled &&
              <React.Fragment>
                <Row className='cardHeader'>
                  <h5 className='categoryCheckbox'>{i18n.t('nightParking')}</h5>
                  <Checkbox
                    className='servicesCheckbox'
                    checked={city.nightParkingActivated}
                    color='default'
                    onChange={async (_, value) => await this._toggleNightParking(value, city)}
                  />
                </Row>
                <Row className='cardHeader'>
                  <h5 className='categoryCheckbox'>{i18n.t('smsAlerts')}</h5>
                  <Checkbox
                    className='servicesCheckbox'
                    checked={city.smsEnabledEdited}
                    color='default'
                    onChange={async (_, value) => await this._toggleSmsEnabled(value, city)}
                  />
                </Row>
                <Row className='cardHeader'>
                  <h5 className='categoryCheckbox'>{i18n.t('voiceAlerts')}</h5>
                  <Checkbox
                    className='servicesCheckbox'
                    checked={city.voiceEnabledEdited}
                    color='default'
                    onChange={async (_, value) => await this._toggleVoiceEnabled(value, city)}
                  />
                </Row>
              </React.Fragment>
            }
          </Col>
        </Row>
        {city.cityEditingEnabled &&
          <Row className='editCityButtonsContainer'>
            <Button
              variant='outlined'
              onClick={async () => await this._cancelEditing(city)}
              className='editCityCancelButton'
            >
              {i18n.t('cancel')}
            </Button>
            <Button
              variant='outlined'
              onClick={async () => await this._modifyCity(city)}
              className='editCityConfirmButton'
            >
              {i18n.t('edit')}
            </Button>
          </Row>
        }
        <Dialog
          open={this.state.openCity}
          onClose={this._handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{i18n.t('confirmationOfDeletion')}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {i18n.t('areYouSureYouWantToDeleteThis')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this._handleClose} color='primary'>
              {i18n.t('cancel')}
            </Button>
            <Button onClick={this._deleteCity} color='primary' autoFocus>
              {i18n.t('confirm')}
            </Button>
          </DialogActions>
        </Dialog>
      </Card>));
  }

  _renderCities() {
    return (
      <React.Fragment>
        <Row>
          <h2 className={'pageTitle'}>{i18n.t('addACarecityCity')}</h2>
        </Row>
        <Form>
          <Row>
            <Col sm='12'>
              <TextField
                variant='standard'
                name='cityName'
                placeholder={i18n.t('addName')}
                fullWidth={true}
                label={i18n.t('enterTheCityName')}
                helperText={i18n.t('thisFieldIsRequired')}
                maxLength='255'
                onChange={this._handleInputChange}
                value={this.state.cityName}
              />
              <br />
            </Col>
          </Row>
          <Row>
            <Col sm='8'>
              <TextField
                variant='standard'
                name='cityCountry'
                placeholder={i18n.t('addCountry')}
                fullWidth={true}
                label={i18n.t('enterTheCountry')}
                helperText={i18n.t('thisFieldIsRequired')}
                maxLength='255'
                onChange={this._handleInputChange}
                value={this.state.cityCountry}
              />
              <br />
            </Col>
            <Col sm='4'>
              <TextField
                variant='standard'
                name='cityState'
                placeholder={i18n.t('addStateOrProvince')}
                fullWidth={true}
                label={i18n.t('enterTheStateOrProvince')}
                helperText={i18n.t('thisFieldIsRequired')}
                maxLength='255'
                onChange={this._handleInputChange}
                value={this.state.cityState}
              />
              <br />
            </Col>
          </Row>
          <Row>
            <Col sm='2'>
              <Button
                fullWidth={true}
                variant='contained'
                onClick={this._addCity}
                className='addCityButton'
              >
                {i18n.t('add')}
              </Button>
            </Col>
          </Row>
          <Dialog
            open={this.state.openCityError}
            onClose={this._handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>{i18n.t('errorDuringCreation')}</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                {i18n.t('youMustEnterTheNameStateAndCountryOfTheNewCarecityCity')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this._handleClose} color='primary'>
                {'Ok'}
              </Button>
            </DialogActions>
          </Dialog>
        </Form>
        <Row>
          <h2 className={'pageTitle'}>{i18n.t('activeCities')}</h2>
        </Row>
        {this._renderCitiesCard()}
      </React.Fragment>
    );
  }

  _renderGeographicCitiesCard() {
    return (
      this.state.geographicCities.map(city =>
        <Card className='card' key={city.id}>
          <CardHeader
            title={city.name + ', ' + city.state + ', ' + city.country}
          />
          <CardContent>
            <Row>
              <Col xs={12} sm={12} md={9} lg={9}>
                <FormControl variant='standard' fullWidth={true}>
                  <InputLabel>{i18n.t('carecityCities')}</InputLabel>
                  <Select
                    variant='standard'
                    value={city.cityId ?? ''}
                    onChange={async (_event) => await this._modifyGeographicCity(city, _event.target.value)}
                  >
                    <MenuItem key={''} value={''}>{''}</MenuItem>
                    {
                      this.state.cities.map(function (city) {
                        return <MenuItem key={city.cityId} value={city.cityId}>{city.name}</MenuItem>;
                      })
                    }
                  </Select>
                </FormControl>
              </Col>
              <Col xs={12} sm={12} md={3} lg={3}>
                <Button
                  fullWidth={true}
                  variant='contained'
                  onClick={async () => await this._handleOpenGeographicCity(city)}
                  className='geographicCityDeleteButton'
                >
                  {i18n.t('remove')}
                </Button>
              </Col>
            </Row>
          </CardContent>
          <Dialog
            open={this.state.openGeographicCity}
            onClose={this._handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>{i18n.t('confirmationOfDeletion')}</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                {i18n.t('areYouSureYouWantToDeleteThis')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this._handleClose} color='primary'>
                {i18n.t('cancel')}
              </Button>
              <Button onClick={this._deleteGeographicCity} color='primary'>
                {i18n.t('confirm')}
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      )
    );
  }

  _renderGeographicCities() {
    return (
      <React.Fragment>
        <Row>
          <h2 className={'pageTitle'}>{i18n.t('managementOfGeographicCities')}</h2>
        </Row>
        {this._renderGeographicCitiesCard()}
      </React.Fragment>
    );
  }

  _renderOtherCitiesDatatables() {
    const lowerCaseQuery = this.state.otherCityQuery.toLowerCase();
    return (
      <React.Fragment>
        <Row>
          <Col sm='6'>
            <TextField
              variant='standard'
              placeholder={i18n.t('enterTheCityName')}
              fullWidth={true}
              label={i18n.t('searchForACity')}
              maxLength='255'
              onChange={this._onOtherCityQueryChange}
              value={this.state.otherCityQuery}
            />
          </Col>
        </Row>
        <Table
          sortDirection={this.state.sortDirection}
          columnToSort={this.state.columnToSort}
          handleSort={this._handleSort}
          handleRemove={this._handleRemoveOtherCity}
          deleteAction={true}
          blockAction={false}
          data={orderBy(
            this.state.otherCityQuery
              ? this.state.otherCities.filter(x =>
                x['city']
                  .toLowerCase()
                  .includes(lowerCaseQuery)
              )
              : this.state.otherCities,
            this.state.columnToSort,
            this.state.sortDirection)}
          header={[
            {
              name: i18n.t('nameOfTheCity'),
              prop: 'city'
            },
            {
              name: i18n.t('numberOfRequests'),
              prop: 'count'
            }
          ]}
        />
        <Dialog
          open={this.state.openOtherCity}
          onClose={this._handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{i18n.t('confirmationOfDeletion')}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {i18n.t('areYouSureYouWantToDeleteThis')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this._handleClose} color='primary'>
              {i18n.t('cancel')}
            </Button>
            <Button onClick={this._deleteOtherCity} color='primary'>
              {i18n.t('confirm')}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

  render() {
    if (User.getInstance().getUser().access_level === 'super_admin') {
      return (
        <Container className='Cities'>
          {this._renderCities()}
          {this._renderGeographicCities()}
          <Row>
            <h2 className={'pageTitle'}>{i18n.t('citiesSuggestedByCitizens')}</h2>
          </Row>
          {this._renderOtherCitiesDatatables()}
          <ToastContainer />
        </Container>
      );
    }
    else {
      return (
        <AccessForbidden />
      );
    }
  }
}

export default Cities;
