import React from 'react';
import AsyncComponent from '../../components/AsyncComponent';
import { Col, Container, Row } from 'reactstrap';
import deburr from 'lodash/deburr';
import { orderBy } from '../../utils/array_helper';
import TextField from '@mui/material/TextField';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);

import Table from '../../components/Table';

import './styles.scss';
import i18n from '../../i18n';

const invertDirection = {
  asc: 'desc',
  desc: 'asc'
};

class AlertDeliveryStatus extends AsyncComponent {
  constructor(props) {
    super(props);
    this.state = {
      columnToSortDeliveryStatus: 'to',
      smsUserQuery: '',
      sortDirectionDeliveryStatus: 'desc',
    };

    this._handleSortDeliveryStatus = this._handleSortDeliveryStatus.bind(this);
    this._onSmsUserQueryChange = this._onSmsUserQueryChange.bind(this);
  }

  async _handleSortDeliveryStatus(columnName) {
    await this.setStateAsync(state => ({
      columnToSortDeliveryStatus: columnName,
      sortDirectionDeliveryStatus:
        state.columnToSortDeliveryStatus === columnName
          ? invertDirection[state.sortDirectionDeliveryStatus]
          : 'desc'
    }));
  }

  async _onSmsUserQueryChange(e) {
    await this.setStateAsync({ smsUserQuery: e.target.value });
  }

  render() {
    const alertDeliveryStatus = this.props.alertDeliveryStatus;
    const lowerCaseSmsQuery = deburr(this.state.smsUserQuery.toLowerCase());
    const alertSmsStats = {
      labels: [
        i18n.t('delivered'),
        i18n.t('sent'),
        i18n.t('undelivered'),
        i18n.t('failed')
      ],
      datasets: [{
        data: [
          alertDeliveryStatus.smsDelivered,
          alertDeliveryStatus.smsSent,
          alertDeliveryStatus.smsUndelivered,
          alertDeliveryStatus.smsFailed
        ],
        backgroundColor: [
          '#0da358',
          '#725ac1',
          '#F5BE41',
          '#EE5F5F'
        ],
        hoverBackgroundColor: [
          '#0da358',
          '#725ac1',
          '#F5BE41',
          '#EE5F5F'
        ]
      }]
    };

    return (
      <Container className='AlertDeliveryStatus'>
        {
          (alertDeliveryStatus.smsDelivery !== null && alertDeliveryStatus.smsDelivery.length > 0) &&
          <React.Fragment>
            <Pie data={alertSmsStats} className="pie" />
            <Row>
              <Col sm='6'>
                <TextField
                  variant='standard'
                  placeholder={i18n.t('enterTheUserPhoneNumber')}
                  fullWidth={true}
                  label={i18n.t('searchForAPhoneNumber')}
                  maxLength='255'
                  onChange={this._onSmsUserQueryChange}
                  value={this.state.smsUserQuery}
                />
              </Col>
            </Row>
            <Table
              sortDirection={this.state.sortDirectionDeliveryStatus}
              columnToSort={this.state.columnToSortDeliveryStatus}
              handleSort={this._handleSortDeliveryStatus}
              data={orderBy(
                lowerCaseSmsQuery
                  ? alertDeliveryStatus.smsDelivery.filter(x =>
                    deburr(x['to'])
                      .toLowerCase()
                      .includes(lowerCaseSmsQuery)
                  )
                  : alertDeliveryStatus.smsDelivery,
                this.state.columnToSortDeliveryStatus,
                this.state.sortDirectionDeliveryStatus)}
              header={[
                {
                  name: i18n.t('phoneNumber'),
                  prop: 'to'
                },
                {
                  name: i18n.t('status'),
                  prop: 'status'
                }
              ]}
            />
          </React.Fragment>
        }
        {
          (alertDeliveryStatus.smsDelivery === null || alertDeliveryStatus.smsDelivery.length === 0) &&
          <h5 className='smsDeliveryStatusNotAvaialbleLabel'>{i18n.t('smsDeliveryStatusNotAvailable')}</h5>
        }
      </Container>
    );
  }
}

export default AlertDeliveryStatus;
